import { IonButton, IonCol, IonContent, IonFooter, IonLabel, IonRow, IonSelectOption, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { createEntity, updateEntity, searchEntity, updateProduct, createProduct } from "../../services";
import { createSlug } from "../../utils/helpers";
import ImageUploader from "../ImageUploader";
import Input from "../Input";
import Modal from "../Modal";
import Select from "../Select";
import uniqid from 'uniqid'
import ReactQuill from "react-quill";

const initialValue = {
    shortName: '',
    name: '',
    description: '',
    shortDescription: '',
    slug: '',
    _categories: [],
    _subCategories: [],
    picUrls: ['']
}

export default function ProductDetail({ isOpen, setOpen, initialProductId, ...rest }) {
    const [formData, setFormData] = useState(initialValue);
    const { categories } = useContext(AppContext)
    const [api, isLoading] = useApi()
    const [toast] = useToast()

    const onSubmit = async () => {
        try {
            if (initialProductId) {
                await api(updateProduct, {...formData, slug: createSlug(`${formData.name}-${uniqid()}`)});
            } else {
                await api(createProduct, {...formData, slug: createSlug(`${formData.name}-${uniqid()}`)})
            }
            setOpen(false)
        } catch (error) {

        }
    }

    const onIonChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const fetchProduct = async () => {
        try {
            const data = await api(searchEntity, { entity: 'product', filter: { _id: initialProductId } })
            if (data.count !== 1) return toast({ message: 'Məhsul tapilmadi.' })
            setFormData(data.data[0])
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        if (initialProductId) fetchProduct()
        else setFormData(initialValue)
    }, [initialProductId])

    return <Modal title="Məhsul detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent className="ion-padding">
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.name,
                            name: 'name'
                        }}
                        label="Ad"
                    />
                </IonCol>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.shortName,
                            name: 'shortName'
                        }}
                        label="Qısa Ad"
                    />
                </IonCol>
            </IonRow>

            <IonRow>
                <IonCol size={12}>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.shortDescription,
                            name: 'shortDescription'
                        }}
                        label="Qısa Açıqlama"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
            <IonCol size={12}>
                    <IonLabel>Açıqlama</IonLabel>
                    <ReactQuill value={formData.description}
                        onChange={e => setFormData({...formData, description: e})} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size={6}>
                    <Select
                        selectProps={{
                            value: formData._categories,
                            name: '_categories',
                            multiple: true,
                            onIonChange: e => setFormData({ ...formData, _categories: e.detail.value })
                        }}
                        label="Kateqoriyalar"
                    >
                        {categories.map(s => (<IonSelectOption key={s._id} value={s._id}>
                            {s.name}
                        </IonSelectOption>))}
                    </Select>
                </IonCol>
                <IonCol size={6}>
                    <Select
                        selectProps={{
                            value: formData._subCategories,
                            name: '_subCategories',
                            multiple: true,
                            onIonChange: e => setFormData({ ...formData, _subCategories: e.detail.value })
                        }}
                        label="Alt Kateqoriyalar"
                    >
                        {categories.filter(s => formData._categories.includes(s._id)).map(s => (<div key={s._id}>
                            {s.subCategories.map(sC => <IonSelectOption key={sC._id} value={sC._id}>
                                <p className="sub-category-name">{sC.name}</p>
                            </IonSelectOption>)}
                        </div>))}
                    </Select>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size={6}>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.struckPrice,
                            name: 'struckPrice'
                        }}
                        label="Reklam Qiyməti"
                    />
                </IonCol>
                <IonCol size={6}>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.price,
                            name: 'price'
                        }}
                        label="Qiymət"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size={12}>
                    <ImageUploader
                        images={formData.picUrls}
                        setImages={e => setFormData({ ...formData, picUrl: e.length > 0 ? e[0] : '' })}
                    />
                </IonCol>
            </IonRow>
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButton disabled={isLoading} onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}