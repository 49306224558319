export default [
    {
        name: 'taxi',
        translation: 'Taksi'
    },
    {
        name: 'market',
        translation: 'Market'
    },
    {
        name: 'restaurant',
        translation: 'Restoran'
    }
]