export default [
    {
        name: 'created',
        translation: 'Yeni Müraciət'
    },
    {
        name: 'inwork',
        translation: 'Cavab Verildi'
    },
    {
        name: 'open',
        translation: 'Yeni mesaj'
    },
    {
        name: 'finish',
        translation: 'Müraciət Bitib'
    }
]