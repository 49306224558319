import { useIonToast } from "@ionic/react";
import { alertCircle, checkmarkCircle, checkmarkDoneCircle, informationCircle } from "ionicons/icons";
import { useState } from "react";

const getIconByKind = (kind) => {
    switch (kind) {
        
        case 'success':
            return checkmarkCircle
        case 'error':
            return alertCircle
        default:
            return informationCircle
    }
}

export default function useToast() {
    const [present, dismiss] = useIonToast();

    const present1 = ({message, duration, kind}) => {
        present({
            message,
            position: 'top',
            icon: getIconByKind(kind),
            duration: duration || 2000,
            cssClass: 'toaster'
        })
    }
    return [present1, dismiss];
  }
