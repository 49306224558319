export default [
    {
        name: 'primary',
        translation: 'Əsas'
    },
    {
        name: 'market',
        translation: 'Market'
    },
    {
        name: 'restaurant',
        translation: 'Restoran'
    }
]