import { Storage } from '@capacitor/storage';

const setValue = async (key, value) => {
  await Storage.set({
    key,
    value
  });
};

const getValue = async (key) => {
  const value = await Storage.get({key});
  return value?.value
};

const removeValue = async (key) => {
  return await Storage.remove({key});
};

export {
  setValue,
  getValue,
  removeValue
}