import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton, IonText } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { ChangeState, DataTable, Header, Toolbox } from "../../components";
import useAuthRequired from "../../hooks/useAuthRequired";
import { dateTime, price } from "../../utils/format";

/// TODO
/*
    1. Payments sehifesi odenish artir sair xerc
    2. 
*/

export default function Payments() {
    useAuthRequired()
    const { settings, user } = useContext(AppContext);
    const paymentTypes = settings.find(s => s.key === 'PAYMENT_TYPES')?.value.types;
    const [isRefetching, setRefetching] = useState(false);
    const [selectedPayments, setSelectedPayments] = useState([]);
    function onSelectedItemsChanged(items) {
        setSelectedPayments(items)
    }


    useEffect(() => {
        if (paymentTypes) setColumns(prev => [{
            label: 'Sənəd növü',
            name: 'type',
            useSearch: true,
            filterOptions: {
                type: 'select-multiple',
                options: paymentTypes.map(p => ({
                    name: p,
                    translation: p
                }))
            }
        }, ...prev])
    }, [paymentTypes])

    const [columns, setColumns] = useState([
        {
            label: 'Məbləğ',
            name: 'amount',
            customFunc: data => <strong>{data > 0
                ? <IonText color="success">{price(data)}</IonText>
                : <IonText color="danger">{price(data)}</IonText>}</strong>
        },
        {
            label: 'Tədarükcü',
            name: '_supplier.name',
            useSearch: true,
            filterOptions: {
                type: 'entity-field',
                name: '_supplier._id',
                entity: 'supplier'
            }
        },
        {
            label: 'Kassa',
            name: '_cashbox.name',
            useSearch: true,
            filterOptions: {
                type: 'entity-field',
                name: '_cashbox._id',
                entity: 'cashbox'
            }
        },
        {
            label: 'Depo',
            name: '_warehouse.name',
            useSearch: true,
            filterOptions: {
                type: 'entity-field',
                name: '_warehouse._id',
                entity: 'warehouse'
            }
        },
        {
            label: 'İstifadəçi',
            name: '_user.displayName',
            useSearch: true,
            filterOptions: {
                type: 'entity-field',
                name: '_user._id',
                entity: 'user',
                showKey: 'displayName'
            }
        },
        {
            label: 'Qeydlər',
            name: 'comment',
            useSearch: true,
        },
        {
            label: 'Tarix',
            name: 'createdAt',
            customFunc: data => <span>{dateTime(data)}</span>
        }
    ])

    const tableButtons = []

    return <IonPage id="Payments">
        <Header title="Sənədlər" />
        <IonContent className="ion-padding">
            {paymentTypes && <DataTable
                columns={columns}
                entity={'payment'}
                onSelectedItemsChanged={onSelectedItemsChanged}
                buttons={tableButtons}
                isRefetching={isRefetching}
                setRefetching={setRefetching}
                populate={'_cashbox _warehouse _supplier _user'}
            />}
        </IonContent>

    </IonPage>
}