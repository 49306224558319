import { IonCol, IonContent, IonButton,IonFooter, IonRow, IonToolbar } from "@ionic/react";
import { useState } from "react";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { sendNotif } from "../../services";
import Input from "../Input";
import Modal from "../Modal";

const intitialValue = {
    _user: '',
    content: '',
    subject: '',
    url: ''
}

export default function SendNotification({ isOpen, setOpen, userIds }) {
    const [api, isLoading] = useApi()
    const [toast] = useToast()
    const [formData, setFormData] = useState(intitialValue);

    const onIonChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const onSubmit = async () => {
        api(sendNotif, {notifications: userIds.map(u => ({...formData, _user: u}))})
        setFormData(intitialValue)
        setOpen(false)
    }

    return <Modal isOpen={isOpen} setOpen={setOpen} title="Notifikasiya göndər">
        <IonContent className="ion-padding">
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.subject,
                            name: 'subject'
                        }}
                        label="Başlıq"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.content,
                            name: 'content'
                        }}
                        label="Kontent"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.url,
                            name: 'url'
                        }}
                        label="URL"
                    />
                </IonCol>
            </IonRow>
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButton disabled={isLoading} onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}