import { IonInput, IonItem, IonLabel, IonSelect } from "@ionic/react";
import './styles.css'

export default function Select({label, selectProps, children, ...rest}) {
    return <IonItem lines="none" id="Select" {...rest}>
        <IonLabel position="stacked">
            {label}
        </IonLabel>
        <IonSelect  okText="Ok" cancelText="Ləğv et" {...selectProps}>
            {children}
        </IonSelect>
    </IonItem>
}