import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton } from "@ionic/react";
import { useState } from "react";
import { ChangeState, DataTable, Header, NewDetail } from "../../components";
import newStates from "../../data/newStates";
import useAuthRequired from "../../hooks/useAuthRequired";
import { dateTime, price } from "../../utils/format";

const NewBadge = ({data}) => {

    switch (data) {
        case 'trash':
            return <IonBadge color="warning">Çöp Qutusu</IonBadge>
        case 'inactive':
                return <IonBadge color="danger">Aktiv Deyil</IonBadge>
        default:
            return <IonBadge color="success">Aktiv</IonBadge>
    }
}

const columns = [
    {
        label: 'Şəkil',
        name: 'photoUrl',
        customFunc: data => <img src={data} />
    },
    {
        label: 'Başlıq',
        name: 'title',
        useSearch: true
    },
    {
        label: 'Status: ',
        name: 'state',
        useSearch:true,
        customFunc: data => <NewBadge data={data}/>,
        filterOptions: {
            type: 'select',
            options: newStates
        }
    },
    {
        label: 'Tarix',
        name: 'createdAt',

        customFunc: data => <span>{dateTime(data)}</span>
    }
]

export default function News() {
    useAuthRequired()
    const [isChangeStateOpen, setChangeStateOpen] = useState(false);
    const [isRefetching, setRefetching] = useState(false);
    const [selectedNews, setSelectedNews] = useState([]);
    const [isNewDetailOpen, setNewDetailOpen] = useState(false)
    function onSelectedItemsChanged(items) {
        setSelectedNews(items)
    }
    function onStateSuccess() {
        setRefetching(true)
    }
    const tableButtons = [
        {
            label: 'Düzəliş et',
            onClick: e => setNewDetailOpen(true),
            disableCondition: selectedNews.length !== 1
        },
        {
            label: 'Status Dəyiş',
            onClick: e => setChangeStateOpen(true),
            disableCondition: selectedNews.length === 0
        }
    ]

    return <IonPage id="News">
        <Header title="Yeniliklər"  right_buttons={[{
            label: 'Yenilik artır',
            isDisabled: selectedNews.length > 0,
            onClick: () => setNewDetailOpen(true)
        }]} />
        <IonContent className="ion-padding">
            <DataTable
                columns={columns}
                entity={'new'}
                onSelectedItemsChanged={onSelectedItemsChanged}
                buttons={tableButtons}
                isRefetching={isRefetching}
                setRefetching={setRefetching}
            />
        </IonContent>
        <NewDetail
            onDidDismiss={() => setRefetching(true)}
            isOpen={isNewDetailOpen}
            setOpen={setNewDetailOpen}
            initialNewId={selectedNews.length === 1 && selectedNews[0]}
        />
        <ChangeState
            isOpen={isChangeStateOpen}
            setOpen={setChangeStateOpen}
            entity={'new'}
            onSuccess={onStateSuccess}
            states={newStates}
            entityIds={selectedNews}
        />
    </IonPage>
}