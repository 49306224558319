import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton } from "@ionic/react";
import { useState } from "react";
import { ChangeState, DataTable, Header, CampaignDetail } from "../../components";
import campaignStates from "../../data/campaignStates";
import useAuthRequired from "../../hooks/useAuthRequired";
import { dateTime, price } from "../../utils/format";

const CampaignBadge = ({data}) => {

    switch (data) {
        case 'trash':
            return <IonBadge color="warning">Çöp Qutusu</IonBadge>
        case 'inactive':
                return <IonBadge color="danger">Aktiv Deyil</IonBadge>
        default:
            return <IonBadge color="success">Aktiv</IonBadge>
    }
}

const columns = [
    {
        label: 'Şəkil',
        name: 'photoUrl',
        customFunc: data => <img src={data} />
    },
    {
        label: 'Başlıq',
        name: 'title',
        useSearch: true
    },
    {
        label: 'URL',
        name: 'url',
        useSearch: true
    },
    {
        label: 'Status: ',
        name: 'state',
        useSearch:true,
        customFunc: data => <CampaignBadge data={data}/>,
        filterOptions: {
            type: 'select',
            options: campaignStates
        }
    },
    {
        label: 'Tarix',
        name: 'createdAt',

        customFunc: data => <span>{dateTime(data)}</span>
    }
]

export default function Campaigns() {
    useAuthRequired()
    const [isChangeStateOpen, setChangeStateOpen] = useState(false);
    const [isRefetching, setRefetching] = useState(false);
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [isCampaignDetailOpen, setCampaignDetailOpen] = useState(false)
    function onSelectedItemsChanged(items) {
        setSelectedCampaigns(items)
    }
    function onStateSuccess() {
        setRefetching(true)
    }
    const tableButtons = [
        {
            label: 'Düzəliş et',
            onClick: e => setCampaignDetailOpen(true),
            disableCondition: selectedCampaigns.length !== 1
        },
        {
            label: 'Status Dəyiş',
            onClick: e => setChangeStateOpen(true),
            disableCondition: selectedCampaigns.length === 0
        }
    ]

    return <IonPage id="Campaigns">
        <Header title="Kampaniyalar"  right_buttons={[{
            label: 'Kampaniya artır',
            isDisabled: selectedCampaigns.length > 0,
            onClick: () => setCampaignDetailOpen(true)
        }]} />
        <IonContent className="ion-padding">
            <DataTable
                columns={columns}
                entity={'campaign'}
                onSelectedItemsChanged={onSelectedItemsChanged}
                buttons={tableButtons}
                isRefetching={isRefetching}
                setRefetching={setRefetching}
            />
        </IonContent>
        <CampaignDetail
            onDidDismiss={() => setRefetching(true)}
            isOpen={isCampaignDetailOpen}
            setOpen={setCampaignDetailOpen}
            initialCampaignId={selectedCampaigns.length === 1 && selectedCampaigns[0]}
        />
        <ChangeState
            isOpen={isChangeStateOpen}
            setOpen={setChangeStateOpen}
            entity={'campaign'}
            onSuccess={onStateSuccess}
            states={campaignStates}
            entityIds={selectedCampaigns}
        />
    </IonPage>
}