import { IonButton, IonContent, IonIcon, IonList, IonSearchbar } from "@ionic/react";
import { funnelSharp } from "ionicons/icons";
import { useEffect, useState } from "react";
import DataTable from "../DataTable";
import Modal from "../Modal";

export default function FilterObjectIdField({ column, setObj  }) {
    const [isOpen, setOpen] = useState(false);
    const [isRefetching, setRefetching] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    const onSelect = (e) => setSelectedData(e)

    useEffect(() => {
        setObj(obj => ({ ...obj, filter: { 
            ...obj.filter, 
            [column.filterOptions.name]: selectedData.length > 0 
                ? {$in: selectedData} 
                : undefined
        } }))
    }, [selectedData])

    return <>
        <IonButton onClick={() => setOpen(true)} color="dark" className="ion-no-margin"
            size="small" fill="clear">
            <strong style={{ textTransform: 'capitalize' }}>{column.label}</strong>
            <IonIcon slot="end" icon={funnelSharp} />
        </IonButton>
        <Modal title={column.label} isOpen={isOpen} setOpen={setOpen}>
            <IonContent>
                <DataTable
                    showButton={false}
                    buttons={[]}
                    hideRefresh
                    isRefetching={isRefetching}
                    setRefetching={setRefetching}
                    {...{entity: column.filterOptions.entity,
                    title: column.label + ' seç',
                    onSelectedItemsChanged: onSelect,
                    columns: [{
                        label: 'Ad',
                        name: column.filterOptions?.showKey || 'name',
                        useSearch: true
                    }]}}
                />
            </IonContent>
        </Modal>
    </>
}