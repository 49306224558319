import { IonButton, IonCol, IonContent, IonFooter, IonRow, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { createReferrer, searchEntity, updateReferrer } from "../../services";
import Input from "../Input";
import Modal from "../Modal";

const initialValue = {
    _user: '',
    name: '',
    percentage: null,
    phoneNumber: '',
}

export default function ReferrerDetail({ isOpen, setOpen, initialReferrerId, ...rest }) {
    const [formData, setFormData] = useState(initialValue);
    const [api, isLoading] = useApi()
    const { setReferrer } = useContext(AppContext);
    const [toast] = useToast()

    const onSubmit = async () => {
        try {
            if (initialReferrerId) {
                const { instance: referrer } = await api(updateReferrer, formData);
                setReferrer(prev => prev.map(p => p._id === referrer._id ? referrer : p))
            } else {
                const { instance: referrer } = await api(createReferrer, formData)
                setReferrer(prev => [...prev, referrer])
            }
            setOpen(false)
        } catch (error) {

        }
    }

    const onIonChange = async e => {
        const { name, value } = e.target;
        if (name === 'phoneNumber' && value.length === 13) {
            try {
                const { data, count } = await searchEntity({
                    entity: 'user',
                    filter: {phoneNumber: value, role: 'referrer'}
                })
                if (count === 1) {
                    setFormData(prev => ({
                        ...prev, 
                       _user: data[0]._id,
                       name: data[0]?.displayName || ''
                   }))
                } else {
                    throw new Error('')
                }
            } catch (error) {
                toast({message: 'Bu nomre ile referans tapilmadi'})
            }
        }
        setFormData(prev => ({ ...prev, [name]: value }))
    }

    const fetchReferrer = async () => {
        try {
            const data = await api(searchEntity, { entity: 'referrer', filter: { _id: initialReferrerId } })
            if (data.count !== 1) return toast({ message: 'Referans tapilmadi.' })
            setFormData(data.data[0])
        } catch (error) {
            console.log(error.message)
        }
    }
    // +994505025052
    useEffect(() => {
        if (initialReferrerId) fetchReferrer()
        else setFormData(initialValue)
    }, [initialReferrerId])

    return <Modal title="Referans detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonFooter>
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.phoneNumber,
                            name: 'phoneNumber'
                        }}
                        label="Nömrə"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.percentage,
                            name: 'percentage',
                            type:"number"
                        }}
                        label="Faiz"
                    />
                </IonCol>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            readOnly: true,
                            value: formData.name,
                            name: 'name'
                        }}
                        label="Ad"
                    />
                </IonCol>
            </IonRow>

            <IonToolbar>
                <IonButton disabled={isLoading} onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}