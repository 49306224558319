import {useState } from "react";
import './styles.css';
import imageLoader from '../../assets/images/img-loader.jpeg'


export default function Image(props) {
    const [imageLoaded, setImageLoaded] = useState(false);

    // useIonViewWillEnter(() => {
    //     setImageLoading(true);
    // })

    return <>
        <img style={{ display: !imageLoaded ? 'none' : 'block' }} onLoad={() => setImageLoaded(true)} {...props} />
        {!imageLoaded && <img src={imageLoader} className="img-skeleton"/> }
    </>
}