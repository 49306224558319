import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton } from "@ionic/react";
import { useContext, useState } from "react";
import { AppContext } from "../../App";
import { ChangeState, DataTable, Header, ProductDetail } from "../../components";
import productStates from "../../data/productStates";
import useAuthRequired from "../../hooks/useAuthRequired";
import { dateTime, price } from "../../utils/format";

const ProductBadge = ({data}) => {

    switch (data) {
        case 'trash':
            return <IonBadge color="warning">Çöp Qutusu</IonBadge>
        case 'inactive':
                return <IonBadge color="danger">Aktiv Deyil</IonBadge>
        default:
            return <IonBadge color="success">Aktiv</IonBadge>
    }
}

export default function Products() {
    useAuthRequired()
    const {categories} = useContext(AppContext)
    const [isChangeStateOpen, setChangeStateOpen] = useState(false);
    const [isRefetching, setRefetching] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isProductDetailOpen, setProductDetailOpen] = useState(false)
    function onSelectedItemsChanged(items) {
        setSelectedProducts(items)
    }
    function onStateSuccess() {
        setRefetching(true)
    }

    
const columns = [
    {
        label: 'Şəkil',
        name: 'picUrls',
        customFunc: data => <img src={data[0]} />
    },
    {
        label: 'Qısa Ad',
        name: 'shortName',
        useSearch:true
    },
    {
        label: 'Status: ',
        name: 'state',
        useSearch:true,
        customFunc: data => <ProductBadge data={data}/>,
        filterOptions: {
            type: 'select',
            options: productStates
        }
    },
    {
        label: 'Ad',
        name: 'name',
        useSearch:true
    },
    {
        label: 'Kateqoriya',
        name: '_categories',
        useSearch:false,
        customFunc: data => <span>{data.map(d => <span key={d}>
            {categories?.find(c => c._id === d)?.name}</span>)}</span>,
        filterOptions: {
            type: 'entity-field',
            name: '_categories',
            entity: 'category'
        }
    },
    {
        label: 'Qiymət',
        name: 'price',
        customFunc: data => price(data)
    },
    {
        label: 'Qısa Açıqlama',
        name: 'shortDescription',
        useSearch:true
    },
    {
        label: 'Tarix',
        name: 'createdAt',
        customFunc: data => <span>{dateTime(data)}</span>
    }
]

    const tableButtons = [
        {
            label: 'Düzəliş et',
            onClick: e => setProductDetailOpen(true),
            disableCondition: selectedProducts.length !== 1
        },
        {
            label: 'Status Dəyiş',
            onClick: e => setChangeStateOpen(true),
            disableCondition: selectedProducts.length === 0
        }
    ]

    return <IonPage id="Products">
        <Header title="Məhsullar"  right_buttons={[{
            label: 'Məhsul artır',
            isDisabled: selectedProducts.length > 0,
            onClick: () => setProductDetailOpen(true)
        }]} />
        <IonContent className="ion-padding">
            <DataTable
                columns={columns}
                entity={'product'}
                onSelectedItemsChanged={onSelectedItemsChanged}
                buttons={tableButtons}
                isRefetching={isRefetching}
                setRefetching={setRefetching}
            />
        </IonContent>
        <ProductDetail
            onDidDismiss={() => setRefetching(true)}
            isOpen={isProductDetailOpen}
            setOpen={setProductDetailOpen}
            initialProductId={selectedProducts.length === 1 && selectedProducts[0]}
        />
        <ChangeState
            isOpen={isChangeStateOpen}
            setOpen={setChangeStateOpen}
            entity={'product'}
            onSuccess={onStateSuccess}
            states={productStates}
            entityIds={selectedProducts}
        />
    </IonPage>
}