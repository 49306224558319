export default [
    {
        name: 'publish',
        translation: 'Aktiv'
    },
    {
        name: 'inactive',
        translation: 'Aktiv deyil'
    },
    {
        name: 'trash',
        translation: 'Silinib'
    }
]