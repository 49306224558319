import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton } from "@ionic/react";
import { useState } from "react";
import { DataTable, Header, CategoryDetail } from "../../components";
import referrerStates from "../../data/referrerStates";
import useAuthRequired from "../../hooks/useAuthRequired";
import useToast from "../../hooks/useToast";
import { deleteEntity } from "../../services";


const columns = [
    {
        label: 'No',
        name: 'position',
    },
    {
        label: 'Şəkil',
        name: 'picUrl',
        customFunc: data => <img src={data} />
    },
    {
        label: 'Ad',
        name: 'name',
        useSearch:true
    },
    {
        label: 'Referans',
        name: 'isReferCodeActive',
        useSearch: false,
        customFunc: data => <IonBadge color={data ?"success":"danger"}>{data ? "Aktiv":"Deaktiv"}</IonBadge>,
        filterOptions: {
            type: 'select',
            options: referrerStates
        }
    },
]

export default function Categories() {
    useAuthRequired()
    const [toast] = useToast()
    const [isRefetching, setRefetching] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isCategoryDetailOpen, setCategoryDetailOpen] = useState(false)
    
    function onSelectedItemsChanged(items) {
        setSelectedCategories(items)
    }
    const deleteCategories = async () => {
        const confirmed = window.confirm('Secilen kateqoriyalar silinecekdir. Qebul edirsiniz ?')
        if (confirmed) {
            try {
                await deleteEntity({entity: 'category', _ids: selectedCategories})
                setRefetching(true)
            } catch (error) {
                toast({message: 'Xeta bas verdi'})
            }
        }
    }
    const tableButtons = [
        {
            label: 'Düzəliş et',
            onClick: e => setCategoryDetailOpen(true),
            disableCondition: selectedCategories.length !== 1
        },
        {
            label: 'Sil',
            onClick: deleteCategories,
            disableCondition: selectedCategories.length === 0
        }
    ]


    const initialObj = {
        limit: 10,
        skip: 0,
        sort: { position: 'asc' },
        filter: {},
    }

    return <IonPage id="Categories">
        <Header title="Kateqoriyalar"  right_buttons={[{
            label: 'Kateqoriya artır',
            isDisabled: selectedCategories.length > 0,
            onClick: () => setCategoryDetailOpen(true)
        }]} />
        <IonContent className="ion-padding">
            <DataTable
                columns={columns}
                entity={'category'}
                onSelectedItemsChanged={onSelectedItemsChanged}
                buttons={tableButtons}
                isRefetching={isRefetching}
                setRefetching={setRefetching}
                customObj={initialObj}
            />
        </IonContent>
        <CategoryDetail
            onDidDismiss={() => setRefetching(true)}
            isOpen={isCategoryDetailOpen}
            setOpen={setCategoryDetailOpen}
            initialCategoryId={selectedCategories.length === 1 && selectedCategories[0]}
        />
    </IonPage>
}