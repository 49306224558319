import { IonButton, IonCol, IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow, IonSelectOption, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../hooks/useApi";
import usePosition from "../../hooks/usePosition";
import useToast from "../../hooks/useToast";
import { createWarehouse, searchEntity, updateWarehouse } from "../../services";
import Input from "../Input";
import Modal from "../Modal";
import { Map, Placemark, SearchControl } from 'react-yandex-maps'
import pin from '../../assets/images/pin.svg';
import { locate } from "ionicons/icons";
import './styles.css'
import Select from "../Select";
import warehouseTypes from "../../data/warehouseTypes";
import { AppContext } from "../../App";

const initialValue = {
    name: '',
    phoneNumber: '',
    minCartTotal: '',
    type: 'primary',
}

export default function WarehouseDetail({ isOpen, setOpen, initialWarehouseId, ...rest }) {
    const mapRef = useRef();
    const {setWarehouses} = useContext(AppContext)
    const [formData, setFormData] = useState(initialValue);
    const [api, isLoading] = useApi()
    const [toast] = useToast()

    const [isMarkerDragged, setMarkerDragged] = useState(false);

    const [position] = usePosition();
    const [markerPosition, setMarkerPosition] = useState(null);

    const reLocate = () => {
        setMarkerPosition(position);
        mapRef.current.setCenter(position, 16);
    }
    useEffect(() => {
        return () => {
            setMarkerPosition([])
            setMarkerDragged(false)
        }
    }, [])

    useEffect(() => {
        if (position !== [] && !isMarkerDragged) setMarkerPosition(position);
    })

    const setPlacemarkDraggedPosition = (e) => {
        try {
            const coordinates = e.originalEvent.target.geometry._coordinates
            setMarkerPosition(coordinates)
        } catch (error) {
            console.log(error.message)
        }
    }

    const onSubmit = async () => {
        try {
            if (initialWarehouseId) {
                const {instance: warehouse} = await api(updateWarehouse, {...formData, location: {coordinates: markerPosition}});
                setWarehouses(prev => prev.map(p => p._id === warehouse._id ? warehouse : p))
            } else {
                const {instance: warehouse} = await api(createWarehouse, {...formData, location: {coordinates: markerPosition}})
                setWarehouses(prev => [...prev, warehouse])
            }
            setOpen(false)
        } catch (error) {
            
        }
    }

    const onIonChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const fetchWarehouse = async () => {
        try {
            const data = await api(searchEntity, { entity: 'warehouse', filter: { _id: initialWarehouseId } })
            if (data.count !== 1) return toast({ message: 'Depo tapilmadi.' })
            setFormData(data.data[0])
            if (data.data[0].location.coordinates) {
                setMarkerDragged(true)
                setMarkerPosition(data.data[0].location.coordinates)
            }
            
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        if (initialWarehouseId) fetchWarehouse()
        else setFormData(initialValue)
    }, [initialWarehouseId])

    return <Modal id="WarehouseDetail" title="Depo detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent>
            <IonRow style={{ height: '100%' }}>
                <IonCol className="ion-no-padding" size={12} sizeMd={7}>
                    <div id="locate-button" onClick={reLocate} color="light">
                        <IonIcon src={locate} color="primary" />
                    </div>
                    {markerPosition && <Map
                        width={'100%'}
                        instanceRef={mapRef}
                        height={'100%'}
                        state={{ center: markerPosition, zoom: 16 }}
                    >
                        <Placemark
                            geometry={position}
                            options={{
                                preset: "islands#circleDotIcon",
                                iconColor: '#5d3ebc'
                            }}
                        />
                        <Placemark
                            geometry={markerPosition}
                            onDragStart={() => setMarkerDragged(true)}
                            onDragEnd={setPlacemarkDraggedPosition}
                            options={{
                                draggable: true,
                                iconLayout: 'default#image',
                                iconImageHref: pin,
                                iconImageSize: [60, 60],
                                iconOffset: [-20, -20],
                            }}
                        />
                    </Map>}
                </IonCol>
                <IonCol size={12} sizeMd={5}>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.name,
                            name: 'name'
                        }}
                        label="Ad"
                    />
                    <div style={{ marginTop: 5 }} />
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.phoneNumber,
                            name: 'phoneNumber'
                        }}
                        label="Nömrə"
                    />

                    <div style={{ marginTop: 5 }} />
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.minCartTotal,
                            name: 'minCartTotal'
                        }}
                        label="Minimum səbət toplamı"
                    />
                    <div style={{ marginTop: 5 }} />
                    <Select selectProps={{
                        onIonChange: e => setFormData({...formData, type: e.detail.value}),
                        value: formData.type
                    }}
                        label="Depo növü">
                        {warehouseTypes.map((w, i) => <IonSelectOption value={w.name} key={i}>
                            {w.translation}
                        </IonSelectOption >)}
                    </Select>
                </IonCol>
            </IonRow>

        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButton disabled={isLoading} onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}