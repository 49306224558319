import { post, put, get, del } from "../utils/api";


const login = async (data) => post('/auth/login', data);

const getToken = async (data) => post('/auth/get-token', data);

const getUser = async (data) => get('/global/get-user', data);

const updateUser = async (data) => post('/auth/update-user', data);

const searchEntity = async (data) => post(`/global/search/${data.entity}`, data)

const createEntity = async (data) => post(`/global/create-entity/${data.entity}`, data)

const updateEntity = async (data) => put(`/global/update-entity/${data.entity}`, data)


const deleteEntity = async (data) => del(`/global/delete-entity/${data.entity}`, data)


const changeStateEntity = async (data) => post(`/global/change-state/${data.entity}`, data)
const changeDeliveryStateEntity = async (data) => post(`/global/change-delivery-state/${data.entity}`, data)

const updateOrder = async data => put(`/global/update-entity/order`, data)

const createCategory = async data => post(`/categories`, data)
const updateCategory = async data => put(`/categories`, data)


const createProduct = async data => post(`/products`, data)
const updateProduct = async data => put(`/products`, data)
const getAllProducts = async () => get("/products/allProducts");
const updateProductCategories = async data => put("/products/updateCategory", data);
const searchProductForCategories = async data => get(`/products/searchProductForCategory?productName=${data.productName}`);

const createNews = async data => post(`/news`, data)
const updateNews = async data => put(`/news`, data)

const createCampaigns = async data => post(`/campaigns`, data)
const updateCampaigns = async data => put(`/campaigns`, data)

const createCourier = async data => post(`/global/create-entity/courier`, data)
const updateCourier = async data => put(`/global/update-entity/courier`, data)

const createCourierTariff = async data => post(`/global/create-entity/courierTariff`, data)
const updateCourierTariff = async data => put(`/global/update-entity/courierTariff`, data)

const createReferrer = async data => post(`/referrer`, data)
const updateReferrer = async data => put(`/referrer`, data)

const createWarehouse = async data => post(`/global/create-entity/warehouse`, data)
const updateWarehouse = async data => put(`/global/update-entity/warehouse`, data)

const createSetting = async data => post(`/global/create-entity/setting`, data);
const updateSetting = async data => put(`/global/update-entity/setting`, data);

const sendNotif = async data => post(`/global/send-notification`, data);


const buyProductFromSupplier = async data => post(`/global/buy-product-from-supplier`, data);

const saleProductToSupplier = async data => post(`/global/sale-product-to-supplier`, data);

const createExtraPayment = async data => post(`/global/create-extra-payment`, data);

const cashboxCheckout = async data => post('/global/cashbox-checkout', data)

const getCashboxAccountingViaDate = async data => post('/global/getCashboxAccountingViaDate', data)

const updateTickets = async data => put(`/tickets`, data)
const getTickets = async () => get('/tickets');
const sendMessage = async (data) => post('/tickets/send-message', data);

const attachCourierToOrder = async (data) => post('/order/attach-courier', data);

export {
    attachCourierToOrder,
    getCashboxAccountingViaDate,
    cashboxCheckout,
    createExtraPayment,
    saleProductToSupplier,
    sendNotif,
    login,
    createNews,
    updateNews,
    createCampaigns,
    updateCampaigns,
    getUser,
    updateUser,
    getToken,
    createProduct,
    updateProduct,
    searchEntity,
    updateOrder,
    createCourier,
    updateCourier,
    createCourierTariff,
    updateCourierTariff,
    createReferrer,
    updateReferrer,
    createWarehouse,
    updateWarehouse,
    createSetting,
    updateSetting,
    changeStateEntity,
    changeDeliveryStateEntity,
    createEntity,
    updateEntity,
    deleteEntity,
    createCategory,
    updateCategory,
    buyProductFromSupplier,
    updateTickets,
    getTickets,
    sendMessage,
    getAllProducts,
    updateProductCategories,
    searchProductForCategories
}