import { IonPage, IonContent } from "@ionic/react";
import { useState } from "react";
import { SettingDetail, DataTable } from "../../components";
import Header from "../../components/Header";
import SettingTable from "../../components/SettingTable";
import useAuthRequired from "../../hooks/useAuthRequired";











const columns = [
    {
        label: 'Tənzimləmə açarı',
        name: 'key',
        useSearch: true
    }
]
export default function Settings() {
    useAuthRequired()
    const [isSettingDetailOpen, setSettingDetailOpen] = useState(false);
    const [selectedSettings, setSelectedSettings] = useState([]);
    const [isRefetching, setRefetching] = useState(false);

    function onSelectedItemsChanged(items) {
        console.log(items)
        setSelectedSettings(items)
    }

    const tableButtons = [
        {
            label: 'Düzəliş et',
            onClick: e => setSettingDetailOpen(true),
            disableCondition: selectedSettings.length !== 1
        }
    ]

    return <IonPage id="Settings">
        <Header title="Tənzimləmələr" right_buttons={[{
            label: 'Tənzimləmə artır',
            isDisabled: selectedSettings.length > 0,
            onClick: () => setSettingDetailOpen(true)
        }]} />
        <IonContent className="ion-padding">
            <SettingTable
                columns={columns}
                entity={'setting'}
                isRefetching={isRefetching}
                setRefetching={setRefetching}
                onSelectedItemsChanged={onSelectedItemsChanged}
                buttons={tableButtons}
            />
        </IonContent>
        <SettingDetail
            onDidDismiss={() => setRefetching(true)}
            isOpen={isSettingDetailOpen}
            setOpen={setSettingDetailOpen}
            initialSettingId={selectedSettings.length === 1 && selectedSettings[0]}
        />
    </IonPage>
}