import { IonCard, IonIcon, IonThumbnail } from "@ionic/react";
import profileImage from '../../assets/images/profile-icon.svg';
import Image from "../Image";

export default function ProfilePhoto({ url }) {

    return <IonCard
        className="ion-no-margin"
        style={{
            background: '#DBDBFF',
            color: 'white',
            fontSize: 40,
            borderRadius: 8,
            width: '100%',
            height: '100%',
            textAlign: 'center',
            
        }}
    >
        {url ? <Image src={url} /> : <IonIcon style={{marginTop: 8}} src={profileImage} />}
    </IonCard>
}