import { IonContent, IonPage, IonRow, IonCol, IonButton, IonIcon, useIonViewWillEnter, useIonToast } from "@ionic/react";
import { Header, Input, OTPModal, SectionTitle } from "../../components";
import './styles.css';
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import useToast from "../../hooks/useToast";
import useApi from "../../hooks/useApi";
import { login } from "../../services";
import { AppContext } from "../../App";



export default function Auth() {
    const [displayName, setDisplayName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneInput, setPhoneInput] = useState('');
    const [expires, setExpires] = useState(0);
    const [api] = useApi()
    const [present, dismiss] = useToast();
    const [isOTPOpen, setOTPOpen] = useState(false);

    useEffect(() => {
        setPhoneNumber('+994' + phoneInput)
    }, [phoneInput])

    async function loginUser() {
        try {
            if (phoneInput.length < 9)
                return present({
                    message: 'Mobil nömrə düzgün qeyd edilməyib.',
                    duration: 2000
                })
            const {expires} = await api(login, { phoneNumber})
            setExpires(expires)
            setOTPOpen(true)
        } catch (error) {
            present({
                message: 'Xəta baş verdi. Təkrar yoxlayın.',
                duration: 2000
            })
        }
    }
    

    return <IonPage id="Login">
        <Header title={'Daxil ol'} />
        <IonContent className="ion-padding">
          
            <IonRow className="form">
                <IonCol size={4}>
                    <Input
                        label="Ölkə kodu"
                        inputProps={{
                            value: '+994',
                            readonly: true,

                            style: {
                                textAlign: 'end'
                            }
                        }}
                    />
                </IonCol>
                <IonCol size={8}>
                    <Input
                        label="Mobil nömrə"
                        inputProps={{
                            placeholder: '505005050',
                            inputmode: 'number',
                            maxlength: 9,
                            name: 'phoneInput',
                            onIonChange: e => setPhoneInput(e.target.value)
                        }}
                    />
                </IonCol>

                <IonCol size={12}>
                    <IonButton onClick={loginUser} expand="block">
                    {'Daxil ol'}
                    </IonButton>
                </IonCol>
                
            </IonRow>

        </IonContent>
        <OTPModal 
        isOpen={isOTPOpen} 
        setOpen={setOTPOpen} 
        expires={expires}
        displayName={displayName}
        phoneNumber={phoneNumber} 
        />
    </IonPage>
}