import { IonButton, IonCol, IonContent, IonFooter, IonRow, IonSelectOption, IonText, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import courierStates from "../../data/courierStates";
import warehouseStates from "../../data/warehouseStates";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { searchEntity, updateOrder } from "../../services";
import { price } from "../../utils/format";
import { sortByDistance } from "../../utils/helpers";
import Checkbox from "../Checkbox";
import Input from "../Input";
import Modal from "../Modal";
import ProductItem from "../ProductItem";
import Select from "../Select";


const initialValue = {
    note: '', leftDoor: false,
    dontRing: false, address: {},
    _courier: '', _warehouse: '',
    payment: {
        cartCost: 0,
        totalCost: 0,
        deliveryCost: 0
    }, cart: []
}

export default function OrderDetail({ isOpen, setOpen, initialOrderId, ...rest }) {
    const { warehouses, couriers, setLoading } = useContext(AppContext);
    const [formData, setFormData] = useState(initialValue);
    const [api, isLoading] = useApi()
    const [toast] = useToast()

    const onSubmit = async () => {
        await api(updateOrder, formData)
        setOpen(false)
    }

    const onIonChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const fetchOrder = async () => {
        setLoading(true)
        try {
            const data = await api(searchEntity, {
                entity: 'order',
                filter: { _id: initialOrderId },
                populate: {
                    path: 'cart',
                    populate: {
                        path: '_product',
                        model: 'Product'
                    }
                }
            })
            if (data.count !== 1) return toast({ message: 'Sifariş tapilmadi.' })
            const newData = data.data[0];
            setFormData(newData)
        } catch (error) {
            console.log(error.message)
        }
        setLoading(false)
    }


    useEffect(() => {
        if (initialOrderId) fetchOrder()
        else setFormData(initialValue)
    }, [initialOrderId])

    return <Modal title="Sifariş detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent className="ion-padding">
            <IonRow>
                <IonCol size={8}>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.note,
                            name: 'note'
                        }}
                        label="Xüsusi Qeydlər"
                    />
                </IonCol>
                <IonCol size={4} className="ion-text-center ion-align-self-center">
                    <strong>{formData.payment?.isPaid
                        ? <IonText color="success">Ödənilib</IonText>
                        : <IonText color="danger">Ödənilməyib</IonText>}</strong>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size={6}>
                    <Checkbox
                        checkboxProps={{
                            onIonChange: e => setFormData({ ...formData, leftDoor: e.detail.value }),
                            value: formData.leftDoor,
                            name: 'leftDoor'
                        }}
                        label="Sifarişi Qapıya Buraxın"
                    />
                </IonCol>
                <IonCol size={6}>
                    <Checkbox
                        checkboxProps={{
                            onIonChange: e => setFormData({ ...formData, dontRing: e.detail.value }),
                            value: formData.dontRing,
                            name: 'dontRing'
                        }}
                        label="Zəngə basma"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Select selectProps={{
                        onIonChange: e => setFormData({ ...formData, _warehouse: e.detail.value }),
                        value: formData._warehouse
                    }}
                        label="Məhsul Depo">
                        {warehouses.map((w, i) => <IonSelectOption value={w._id} key={i}>
                            {w.name} - {warehouseStates.find(c => c.name === w.state).translation}
                        </IonSelectOption >)}
                    </Select>
                </IonCol>
                {formData.address.location && <IonCol>
                    <Select selectProps={{
                        onIonChange: e => setFormData({ ...formData, _courier: e.detail.value }),
                        value: formData._courier
                    }}
                        label="Məhsul Kuryer">
                        {sortByDistance(couriers, formData.address.location.coordinates).map((w, i) => <IonSelectOption value={w._id} key={i}>
                            {w.name} - {courierStates.find(c => c.name === w.state)?.translation}
                        </IonSelectOption >)}
                    </Select>
                </IonCol>}
            </IonRow>
            <IonRow>
                <IonCol size={4}>
                    <Input
                        inputProps={{
                            value: price(formData.payment.cartCost),
                            readOnly: true
                        }}
                        label="Səbət Toplamı"
                    />
                </IonCol>
                <IonCol size={4}>
                    <Input
                        inputProps={{
                            value: price(formData.payment.deliveryCost),
                            readOnly: true
                        }}
                        label="Daşınma Məbləği"
                    />
                </IonCol>
                <IonCol size={4}>
                    <Input
                        inputProps={{
                            value: price(formData.payment.totalCost),
                            readOnly: true
                        }}
                        label="Toplam"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                {formData.cart.map(c => <IonCol size={3} key={c._id}>
                    <ProductItem product={c._product} cart={formData.cart}
                        setCart={updatedCart => setFormData({ ...formData, cart: updatedCart })}
                    />
                </IonCol>)}
            </IonRow>
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButton disabled={isLoading} onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}