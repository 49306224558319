import { IonButton, IonIcon } from "@ionic/react";
import { funnelSharp } from "ionicons/icons";
import { forwardRef, useEffect, useState } from "react";
import DatePicker from 'react-datepicker'

export default function FilterDateField({ column, setObj, obj }) {
    const [startAt, setStartAt] = useState(null)
    const [endAt, setEndAt] = useState(null)

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    useEffect(() => {
        if (startAt) 
            setObj({
                ...obj, filter: {
                    ...obj.filter,
                    createdAt: {
                        ...obj.filter.createdAt,
                        $gte: formatDate(startAt)
                    }
                }
            })
        else setObj({
            ...obj, filter: {
                ...obj.filter,
                createdAt: undefined
            }
        })
    }, [startAt])

    useEffect(() => {
        if (endAt) 
            setObj({
                ...obj, filter: {
                    ...obj.filter,
                    createdAt: {
                        ...obj.filter.createdAt,
                        $lte: formatDate(endAt)
                    }
                }
            })
        else setObj({
            ...obj, filter: {
                ...obj.filter,
                createdAt: startAt ? {
                    ...obj.filter.createdAt,
                    $lte: undefined
                } : undefined
            }
        })
    }, [endAt])

    // useEffect(() => {
    //     setStartAt(obj.filter.createdAt?.$gte || '')
    //     setEndAt(obj.filter.createdAt?.$lte || '')
    // }, [obj.filter.createdAt])

    // useEffect(() => {
    //     if (startAt.length === 10)
    //         
    //     if (startAt.length === 0) setObj({
    //         ...obj, filter: {
    //             ...obj.filter,
    //             createdAt: {
    //                 ...obj.filter.createdAt,
    //                 $gte: undefined
    //             }
    //         }
    //     })
    // }, [startAt])

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartAt(start);
        setEndAt(end);
    };
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <IonButton ref={ref} onClick={onClick} color="dark" className="ion-no-margin"
            size="small" fill="clear">
            <strong style={{ textTransform: 'capitalize' }}>{column.label}</strong>
            <IonIcon slot="end" icon={funnelSharp} />
        </IonButton>
    ));
    /*
    TODOS:

    Admin:
    1. created at filter
    2. courier check
    

    
    setObj(obj => ({ ...obj, filter: { 
            ...obj.filter, 
            createdAt: {
                $gte: '',
                $lte: ''
            }
        } }))
    
    */

    return (
        <DatePicker
            startDate={startAt}
            endDate={endAt}
            selected={startAt}
            selectsRange
            isClearable
            dateFormat="yyyy-MM-dd"
            withPortal
            onChange={onChange}
            customInput={<CustomInput />}
        />
    );


}