import { useIonAlert } from "@ionic/react";
import { useHistory } from "react-router";

export default function useAlert() {
    const history = useHistory();
    const [present, dismiss] = useIonAlert();
    
    const present1 = ({message, href, header}) => {
        present({
            header,
            cssClass: 'getir-alert',
            message,
            buttons: [
              { text: 'Ok', handler: (d) => href && history.push(href) },
            ]
          })
    }
    return [present1, dismiss];
  }
