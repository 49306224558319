import { IonButton, IonCol, IonContent, IonFooter, IonLabel, IonRow, IonSelectOption, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { createEntity, updateEntity, searchEntity } from "../../services";
import Input from "../Input";
import Modal from "../Modal";
import Select from "../Select";
import ReactQuill from 'react-quill'
import ImageUploader from "../ImageUploader";

const initialValue = {
    name: ''
}

export default function SupplierDetail({ isOpen, setOpen, initialSupplierId, ...rest }) {
    const [formData, setFormData] = useState(initialValue);
    const { categories } = useContext(AppContext)
    const [api, isLoading] = useApi()
    const [toast] = useToast()

    const onSubmit = async () => {
        try {
            if (initialSupplierId) {
                await api(updateEntity, {...formData, entity: 'supplier'});
            } else {
                await api(createEntity, {...formData, entity: 'supplier'})
            }
            setOpen(false)
        } catch (error) {

        }
    }

    const onIonChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const fetchSupplier = async () => {
        try {
            const data = await api(searchEntity, { entity: 'supplier', filter: { _id: initialSupplierId } })
            if (data.count !== 1) return toast({ message: 'Yenilik tapilmadi.' })
            setFormData(data.data[0])
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        if (initialSupplierId) fetchSupplier()
        else setFormData(initialValue)
    }, [initialSupplierId])

    return <Modal title="Tədarükcü detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent className="ion-padding">
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.name,
                            name: 'name'
                        }}
                        label="Ad"
                    />
                </IonCol>
            </IonRow>
           
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButton disabled={isLoading} onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}