import Modal from "../Modal";
import TicketMessages from "../TicketMessages";







export default function TicketModal({ isOpen, setOpen, selectedTicket, setSelectedTicket, ...rest }) {

    return <Modal
        isOpen={isOpen}
        {...rest}
        title={selectedTicket.subject}
        setOpen={setOpen}>

        <TicketMessages
            selectedTicket={selectedTicket}
            setSelectedTicket={setSelectedTicket}
        />
    </Modal>
}