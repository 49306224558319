import { IonButton, IonCol, IonContent, IonFooter, IonLabel, IonRow, IonSelectOption, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { createEntity, updateEntity, searchEntity, updateCampaigns, createCampaigns } from "../../services";
import Input from "../Input";
import Modal from "../Modal";
import Select from "../Select";
import ReactQuill from 'react-quill'
import ImageUploader from "../ImageUploader";

const initialValue = {
    title: '',
    content: '',
    photoUrl: '',
    state: 'publish',
    url: ''
}

export default function CampaignDetail({ isOpen, setOpen, initialCampaignId, ...rest }) {
    const [formData, setFormData] = useState(initialValue);
    const [api, isLoading] = useApi()
    const [toast] = useToast()

    const onSubmit = async () => {
        try {
            if (initialCampaignId) {
                await api(updateCampaigns,formData);
            } else {
                await api(createCampaigns, formData)
            }
            setOpen(false)
        } catch (error) {

        }
    }

    const onIonChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const fetchCampaign = async () => {
        try {
            const data = await api(searchEntity, { entity: 'campaign', filter: { _id: initialCampaignId } })
            if (data.count !== 1) return toast({ message: 'Kampaniya tapilmadi.' })
            setFormData(data.data[0])
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        if (initialCampaignId) fetchCampaign()
        else setFormData(initialValue)
    }, [initialCampaignId])

    return <Modal title="Kampaniya detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent className="ion-padding">
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.title,
                            name: 'title'
                        }}
                        label="Başlıq"
                    />
                </IonCol>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.url,
                            name: 'url'
                        }}
                        label="URL"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                <ImageUploader
                        images={[formData.photoUrl]}
                        setImages={e => setFormData({...formData, photoUrl: e.length > 0 ? e[0]: ''})}
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size={12}>
                    <IonLabel>Kontent</IonLabel>
                    <ReactQuill value={formData.content}
                        onChange={e => setFormData({...formData, content: e})} />
                </IonCol>
            </IonRow>

        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButton disabled={isLoading} onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}