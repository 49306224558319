import {decode} from 'base64-arraybuffer'
import AWS from 'aws-sdk'

const bucketName = 'tezgel-assets';

const s3 = new AWS.S3({
    accessKeyId: '2K33QT4IGIK4VJOQ3BQM',
    region: 'fra1',
    endpoint: 'https://fra1.digitaloceanspaces.com',
    secretAccessKey: 'ToVG6T19g+dvAENoud1/RzO7UFUmGOoaor9ZfY+8bL8',
});

const s3Uploader = async (base64, fileName) => {
    return new Promise((resolve, reject) => {
        const bufferData = decode(base64.replace(/^data:image\/\w+;base64,/, ""))
        const type = base64.split(';')[0].split('/')[1];
        s3.putObject({
            Bucket: bucketName,
            Key: `${fileName}.${type}`,
            Body: bufferData,
            ContentEncoding: 'base64', // required
            ContentType: `image/${type}`,
            ACL: 'public-read'
        }, (err, data) => {
            resolve(`https://${bucketName}.fra1.digitaloceanspaces.com/${fileName}`)
        });
    })
}
// sudo mkdir -p /var/www/tezgel.az/html
// sudo chown -R $USER:$USER /var/www/tezgel.az/html
// sudo chmod -R 755 /var/www/tezgel.az
// sudo nano /var/www/tezgel.az/html/index.html
// sudo nano /etc/nginx/sites-available/tezgel.az


export default s3Uploader;