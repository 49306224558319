import { IonCard, IonCardContent, IonContent, IonIcon, IonInput } from "@ionic/react";
import { refresh, refreshOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { getToken, login, updateUser } from "../../services";
import { setValue } from "../../utils/storage";
import Input from "../Input";
import Modal from "../Modal";
import './styles.css';

export default function OTPModal({ isOpen, setOpen, phoneNumber, isRegister, displayName }) {
    const [otp, setOtp] = useState('');
    const history = useHistory();
    const {setUser} = useContext(AppContext);
    const [api] = useApi();

    const onChange = (e) => {
        setOtp(e.target.value);
    }


    async function verifyCode(code) {
        try {
            let {token, user} = await api(getToken, {isRegister, code, phoneNumber})
            setValue('@token', token)
            
            if (isRegister) {
                await api(updateUser, {displayName});
                user = {...user, displayName}
            }
            setUser(user)
            setOpen(false)
            history.push('/home');
            
        } catch (error) {
            setOtp('');
        }
    }

    async function resendCode() {
        try {
            await api(login, {isRegister, phoneNumber})
        } catch (error) {
            
        }
    }

    useEffect(() => {
        if (otp.length === 4) verifyCode(otp)
    }, [otp])

    return <div >
        <Modal title="Aktivasiya kodu" isOpen={isOpen} setOpen={setOpen}>
            <IonContent>
                <IonCard id="OTPModal" className=" ion-no-margin ion-padding" style={{borderRadius: 0}}>
                    <IonCardContent className="ion-text-center">
                        <div style={{width: 300, margin: 'auto'}}>
                        <span style={{fontSize: 14, fontWeight: 500, color: 'black'}}>
                            {phoneNumber} nömrəsinə göndərilən aktivasiya kodunu daxil edin.
                        </span>
                        <IonInput placeholder="0000" onIonChange={onChange} maxlength={4} inputMode="numeric"/>
                        <span style={{fontSize: 12, fontWeight: 500, cursor: 'pointer'}} onClick={resendCode}>
                            Kodu təkrar göndər
                        </span>
                        </div>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </Modal>
    </div>
}