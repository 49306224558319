import {IonButton, IonCol, IonContent, IonRow, IonToolbar} from "@ionic/react";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../App";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import {createCourierTariff, searchEntity, updateCourierTariff} from "../../services";
import Input from "../Input";
import Modal from "../Modal";

const initialValue = {
    km: 0,
    minPrice: 0,
    price: 0,
}

export default function CourierTariffDetail({ isOpen, setOpen, initialCourierId, ...rest }) {
    const [formData, setFormData] = useState(initialValue);
    const [api, isLoading] = useApi()
    const { setCouriers } = useContext(AppContext);
    const [toast] = useToast()

    const onSubmit = async () => {
        try {
            if (initialCourierId) {
                const { instance: courierTariff } = await api(updateCourierTariff, formData);
                setCouriers(prev => prev.map(p => p._id === courierTariff._id ? courierTariff : p))
            } else {
                return toast({message: 'Limit dolub.'})
                const { instance: courierTariff } = await api(createCourierTariff, formData)
                setCouriers(prev => [...prev, courierTariff])
            }
            setOpen(false)
        } catch (error) {

        }
    }

    const onIonChange = async e => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: Number(value) }))
    }

    const fetchCourierTariff = async () => {
        try {
            const data = await api(searchEntity, { entity: 'courierTariff', filter: { _id: initialCourierId } })
            if (data.count !== 1) return toast({ message: 'Tarif tapilmadi.' })
            setFormData(data.data[0])
        } catch (error) {
            console.log(error.message)
        }
    }
    // +994505025052
    useEffect(() => {
        if (initialCourierId) fetchCourierTariff()
        else setFormData(initialValue)
    }, [initialCourierId])

    return <Modal title="Tarif detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent>
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.km,
                            name: 'km',
                            type: 'number',
                            readOnly: true
                        }}
                        label="KM"
                    />
                </IonCol>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.price,
                            name: 'price',
                            type: 'number',
                            readOnly: formData.km === 1 ? true: false
                        }}
                        label="Qiymet"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.minPrice,
                            name: 'minPrice',
                            type: 'number',
                            readOnly: formData.km === 1 ? false: true
                        }}
                        label="Minimum Qiymet"
                    />
                </IonCol>
            </IonRow>

            <IonToolbar>
                <IonButton disabled={isLoading} onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonContent>
    </Modal>
}