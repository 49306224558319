import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonText,
  IonThumbnail,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { appsSharp, archiveOutline, chatboxEllipses, archiveSharp, bag, bagSharp, beerSharp, bookmarkOutline, carOutline, carSharp, cashSharp, checkboxSharp, cogSharp, constructSharp, documentTextSharp, fastFoodSharp, giftSharp, heartOutline, heartSharp, home, homeSharp, logOutSharp, mailOutline, mailSharp, man, newspaperSharp,people,  paperPlaneOutline, paperPlaneSharp, person, storefront, storefrontSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './styles.css';
import Logo from '../Logo';
import ProfilePhoto from '../ProfilePhoto';
import { useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../App';
import { menuController } from '@ionic/core';


const appPages = [
  {
    title: 'Əsas Səhifə',
    url: '/home',
    icon: homeSharp
  },
  {
    title: 'İstifadəçilər',
    url: '/users',
    icon: person
  },
  {
    title: 'Sifarişlər',
    url: '/orders',
    icon: bagSharp
  },
  {
    title: 'Məhsullar',
    url: '/products',
    icon: fastFoodSharp
  },
  {
    title: 'Kuryerlər',
    url: '/couriers',
    icon: carSharp
  },
  {
    title: 'Kateqoriyalar',
    url: '/categories',
    icon: appsSharp
  },
  {
    title: 'Sənədlər',
    url: '/payments',
    icon: documentTextSharp
  },
  {
    title: 'Stoklar',
    url: '/stocks',
    icon: checkboxSharp
  },
  {
    title: 'Kassalar',
    url: '/cashboxes',
    icon: cashSharp
  },
  {
    title: 'Tədarükçülər',
    url: '/suppliers',
    icon: archiveSharp
  },
  {
    title: 'Yeniliklər',
    url: '/news',
    icon: newspaperSharp
  },
  {
    title: 'Referanslar',
    url: '/referrer',
    icon: people
  },
  {
    title: 'Kampanyalar',
    url: '/campaigns',
    icon: giftSharp
  },
  {
    title: 'Depo, Mağaza və Restoranlar',
    url: '/warehouses',
    icon: storefrontSharp
  },
  {
    title: 'Müraciətlər',
    url: '/tickets',
    icon: chatboxEllipses
  },
  {
    title: 'Tənzimləmələr',
    url: '/settings',
    icon: constructSharp
  },
];


const Menu = (props) => {
  const { user, logOut } = useContext(AppContext)
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader><Logo /></IonListHeader>
          <IonItem className='ion-margin-vertical' lines="none">
            <IonThumbnail slot='start'>
              <ProfilePhoto />
            </IonThumbnail>
            <IonLabel>
              <IonText>
                <p><strong>{user?.displayName || 'Ad yazilmayib'} | {user?.role}</strong></p>
                {user?.phoneNumber}
              </IonText>
            </IonLabel>
            <IonButton onClick={logOut} slot="end" fill="clear">
              <IonIcon slot="icon-only" icon={logOutSharp} />
            </IonButton>
          </IonItem>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" icon={appPage.icon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
