import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton } from "@ionic/react";
import { useState } from "react";
import { ChangeState, UserDetail, DataTable, SendNotification } from "../../components";
import Header from "../../components/Header";
import userRoles from "../../data/userRoles";
import useAuthRequired from "../../hooks/useAuthRequired";
import { dateTime, price } from "../../utils/format";

const UserBadge = ({ data }) => {
    const stateValue = userRoles.find(o => o.name === data).translation
    return <IonBadge>{stateValue}</IonBadge>
}

const columns = [
    {
        label: 'Ad',
        name: 'displayName',
        useSearch: true
    },
    {
        label: 'Nömrə',
        name: 'phoneNumber',
        useSearch: true
    },
    {
        label: 'Rol',
        name: 'role',
        useSearch: false,
        customFunc: data => <UserBadge data={data} />,
        filterOptions: {
            type: 'select',
            options: userRoles
        }
    },
    {
        label: 'Tarix',
        name: 'createdAt',
        customFunc: data => <span>{dateTime(data)}</span>
    }
]

export default function Users() {
    useAuthRequired()
    const [isUserDetailOpen, setUserDetailOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isRefetching, setRefetching] = useState(false);
    const [isNotifOpen, setNotifOpen] = useState(false);

    function onSelectedItemsChanged(items) {
        setSelectedUsers(items)
    }

    const tableButtons = [
        {
            label: 'Düzəliş et',
            onClick: e => setUserDetailOpen(true),
            disableCondition: selectedUsers.length !== 1
        },
        {
            label: 'Notifikasiya',
            onClick: e => setNotifOpen(true),
            disableCondition: selectedUsers.length === 0
        },
    ]

    return <IonPage id="Users">
        <Header title="İstifadəçilər" right_buttons={[{
            label: 'İstifadəçi artır',
            isDisabled: selectedUsers.length > 0,
            onClick: () => setUserDetailOpen(true)
        }]} />
        <IonContent className="ion-padding">
            <DataTable
                columns={columns}
                entity={'user'}
                isRefetching={isRefetching}
                setRefetching={setRefetching}
                onSelectedItemsChanged={onSelectedItemsChanged}
                buttons={tableButtons}
            />
        </IonContent>
        <UserDetail
            onDidDismiss={() => setRefetching(true)}
            isOpen={isUserDetailOpen}
            setOpen={setUserDetailOpen}
            initialUserId={selectedUsers.length === 1 && selectedUsers[0]}
        />
        <SendNotification 
            isOpen={isNotifOpen}
            setOpen={setNotifOpen}
            userIds={selectedUsers}
        />
    </IonPage>
}
