import { IonSearchbar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCheckbox, IonCol, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonRow, IonSelectOption, IonToolbar, IonImg } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { searchEntity, updateCategory, createCategory } from "../../services";
import Input from "../Input";
import Modal from "../Modal";
import uniqid from 'uniqid'
import { createSlug } from "../../utils/helpers";
import ImageUploader from "../ImageUploader";
import { getAllProducts, updateProductCategories, searchProductForCategories } from "../../services";
import Image from "../Image";
import Checkbox from "../Checkbox";

export default function AddProdToCat({ isOpen, setOpen, initialCategoryId, subCategoryId, ...rest }) {
    const [formData, setFormData] = useState([]);
    const [products, setProducts] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [isChecked, setIsChecked] = [false];
    const [api] = useApi();
    const [toast] = useToast();

    const checkProductHandler = async (productId, checked) => {
        console.log(productId + " " + checked);
        const updatedProduct = await updateProductCategories({
            productId: productId,
            categoryId: initialCategoryId,
            subCategoryId: subCategoryId,
            update: checked
        });
        console.log(updatedProduct);
    }

    const searchProductHandler = (event) => {
        setSearchValue(event.detail.value);
    }

    useEffect(() => {
        const fetchProducts = async () => {
            const productsResponse = await getAllProducts();
            setProducts(productsResponse.products);
            console.log(products);
        }
        fetchProducts();
    }, []);

    useEffect(() => {
        const getSearchProducts = async () => {
            const searchProducts = await searchProductForCategories({productName: searchValue});
            setProducts(searchProducts.products);
        }
        getSearchProducts();
    }, [searchValue]);

    return (
    <Modal title="Məhsullar"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent className="ion-padding">
            <IonSearchbar
                style={{ maxWidth: 200 }}
                // value={searchValue}
                className="ion-no-margin"
                placeholder="Axtar..."
                onIonChange={searchProductHandler}
            />
            {
                products.map(product => (
                    <IonCard className="ion-padding">
                        <IonCheckbox checked={subCategoryId === product._subCategories[0] ? true : false } onIonChange={(e) => checkProductHandler(product._id, e.target.checked)} />
                        <IonImg style={{ height: "40px", width: "50px" }} src={product.picUrls[0] ? product.picUrls[0] : "https://static.thenounproject.com/png/4700132-200.png" }/>
                        <p>{product.name}</p>
                    </IonCard>
                ))
            }
        </IonContent>
    </Modal>
    )
}