export default [
    {
        name: 'idle',
        translation: 'Aktiv'
    },
    {
        name: 'busy',
        translation: 'Məşğul'
    },
    {
        name: 'offline',
        translation: 'Offline'
    }
]