export default [
    {name:'accepted',translation:"Qəbul"},
    {name:'at-warehouse',translation:"Depoda"},
    {name:'at-address',translation:"Adresdə"},
    {name:'delivered',translation:"Təhvil verildi"},
    {name:'hot',translation:"Qaynar"},
    {name:'on-way',translation:"Yolda"},
    {name:'delay',translation:"Gecikir"},
]

