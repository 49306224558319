import { IonButton, IonCard, IonCardContent, IonLabel } from '@ionic/react';
import React from 'react';
import ImageUploading from 'react-images-uploading';
import s3Uploader from '../utils/s3-uploader';
import uniqid from 'uniqid'
import useToast from '../hooks/useToast';

export default function ImageUploader({images, setImages}) {
    images = images.map(i => ({data_url: i}))
    const [toast] = useToast()
    const maxNumber = 1;

    const onChange = async (imageList, addUpdateIndex) => {
        
        try {
            const image = imageList[0].data_url

            setImages([image])
        } catch (error) {
            toast({message: error.message})
        }
    };

    return (
        <div className="App">
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        <div

                            className='ion-no-margin'
                            style={{display: 'none',borderRadius: 5, border: isDragging 
                                ? '2px dashed var(--ion-color-primary)' 
                                : '2px dashed var(--ion-color-medium)' }}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            <IonCardContent>
                                Şəkili buraya sürüştür və ya kliklə
                            </IonCardContent>
                        </div>
                        <IonLabel>Şəkl</IonLabel>
                        {
                            imageList.length > 0 ? 
                            imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                    <img style={{ margin: 2, borderRadius: 5 }} src={image['data_url']} alt="" width="100" />
                                    <div className="image-item__btn-wrapper">
                                        <IonButton size={'small'} onClick={() => onImageUpdate(index)}>Dəyiş</IonButton>
                                        <IonButton size={'small'} onClick={() => onImageRemove(index)}>Sil</IonButton>
                                    </div>
                                </div>
                            ))
                            :
                            (<div className="image-item">
                                {/* <img style={{ margin: 2, borderRadius: 5 }} alt="" width="100" /> */}
                                <div className="image-item__btn-wrapper">
                                    <IonButton size={'small'} onClick={() => onImageUpdate()}>Dəyiş</IonButton>
                                    <IonButton size={'small'} onClick={() => onImageRemove()} disabled>Sil</IonButton>
                                </div>
                            </div>)
                        }
                    </div>
                )}
            </ImageUploading>
        </div>
    );
}