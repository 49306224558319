import { IonItem, IonLabel, IonCheckbox } from "@ionic/react";
import './styles.css'

export default function Checkbox({label, checkboxProps, ...rest}) {

    return <IonItem lines="none" id="Checkbox" {...rest}>
        <IonLabel>
            {label}
        </IonLabel>
        <IonCheckbox slot="start" {...checkboxProps}/>
    </IonItem>
}
