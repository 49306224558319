import { IonButton, IonCol, IonContent, IonFooter, IonLabel, IonRow, IonSelectOption, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { createEntity, updateEntity, searchEntity, updateNews, createNews } from "../../services";
import Input from "../Input";
import Modal from "../Modal";
import Select from "../Select";
import ReactQuill from 'react-quill'
import ImageUploader from "../ImageUploader";

const initialValue = {
    title: '',
    content: '',
    photoUrl: '',
    state: 'publish'
}

export default function NewDetail({ isOpen, setOpen, initialNewId, ...rest }) {
    const [formData, setFormData] = useState(initialValue);
    const { categories } = useContext(AppContext)
    const [api, isLoading] = useApi()
    const [toast] = useToast()

    const onSubmit = async () => {
        try {
            if (initialNewId) {
                await api(updateNews, formData);
            } else {
                await api(createNews, formData)
            }
            setOpen(false)
        } catch (error) {

        }
    }

    const onIonChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const fetchNew = async () => {
        try {
            const data = await api(searchEntity, { entity: 'new', filter: { _id: initialNewId } })
            if (data.count !== 1) return toast({ message: 'Yenilik tapilmadi.' })
            setFormData(data.data[0])
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        if (initialNewId) fetchNew()
        else setFormData(initialValue)
    }, [initialNewId])

    return <Modal title="Yenilik detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent className="ion-padding">
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.title,
                            name: 'title'
                        }}
                        label="Başlıq"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                <ImageUploader
                        images={[formData.photoUrl]}
                        setImages={e => setFormData({...formData, photoUrl: e.length > 0 ? e[0]: ''})}
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size={12}>
                    <IonLabel>Kontent</IonLabel>
                    <ReactQuill value={formData.content}
                        onChange={e => setFormData({...formData, content: e})} />
                </IonCol>
            </IonRow>

        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButton disabled={isLoading} onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}