import { useEffect, useState } from "react";
import { Geolocation } from '@awesome-cordova-plugins/geolocation';
import useToast from "./useToast";


export default function usePosition() {
  const [position, setPosition] = useState(null);
  const [toast] = useToast();

  useEffect(() => {
    const watch = Geolocation.watchPosition({ maximumAge: 3000, enableHighAccuracy: true });
    const sub = watch.subscribe((data) => {
      try {
        if (data.coords.latitude)
          setPosition([data.coords.latitude, data.coords.longitude])

      } catch (error) {
        console.log(error.message)
        toast({ message: 'Konum tapılmadı. Zəhmət olmasa, GPSin açıq olub olmadığını yoxlayın.' })
      }
    })
    return () => {
      setPosition([])
      sub.unsubscribe();
    }
  }, [])
  return [position];
}
