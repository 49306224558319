import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton } from "@ionic/react";
import { useState } from "react";
import { DataTable, Header, SupplierDetail } from "../../components";
import useAuthRequired from "../../hooks/useAuthRequired";
import { price } from "../../utils/format";

const columns = [
    {
        label: 'Ad',
        name: 'name',
        useSearch: true
    },
    {
        label: 'Balans',
        name: 'wallet',
        customFunc: data => <span>{price(data)}</span>
    }
]

export default function Suppliers() {
    useAuthRequired()
    const [isRefetching, setRefetching] = useState(false);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [isSupplierDetailOpen, setSupplierDetailOpen] = useState(false)
    function onSelectedItemsChanged(items) {
        setSelectedSuppliers(items)
    }

    const tableButtons = [
        {
            label: 'Düzəliş et',
            onClick: e => setSupplierDetailOpen(true),
            disableCondition: selectedSuppliers.length !== 1
        }
    ]

    return <IonPage id="Suppliers">
        <Header title="Tədarükçülər"  right_buttons={[{
            label: 'Tədarükçü artır',
            isDisabled: selectedSuppliers.length > 0,
            onClick: () => setSupplierDetailOpen(true)
        }]} />
        <IonContent className="ion-padding">
            <DataTable
                columns={columns}
                entity={'supplier'}
                onSelectedItemsChanged={onSelectedItemsChanged}
                buttons={tableButtons}
                isRefetching={isRefetching}
                setRefetching={setRefetching}
            />
        </IonContent>
        <SupplierDetail
            onDidDismiss={() => setRefetching(true)}
            isOpen={isSupplierDetailOpen}
            setOpen={setSupplierDetailOpen}
            initialSupplierId={selectedSuppliers.length === 1 && selectedSuppliers[0]}
        />
    </IonPage>
}