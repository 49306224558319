import { IonButton, IonCol, IonContent, IonFooter, IonLabel, IonRow, IonSelectOption, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { createEntity, updateEntity, searchEntity } from "../../services";
import Input from "../Input";
import Modal from "../Modal";
import Select from "../Select";
import ReactQuill from 'react-quill'
import ImageUploader from "../ImageUploader";

const initialValue = {
    name: ''
}

export default function CashboxDetail({ isOpen, setOpen, initialCashboxId, ...rest }) {
    const [formData, setFormData] = useState(initialValue);
    const { categories } = useContext(AppContext)
    const [api, isLoading] = useApi()
    const [toast] = useToast()

    const onSubmit = async () => {
        try {
            if (initialCashboxId) {
                await api(updateEntity, {...formData, entity: 'cashbox'});
            } else {
                await api(createEntity, {...formData, entity: 'cashbox'});
            }
            setOpen(false)
        } catch (error) {

        }
    }

    const onIonChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const fetchCashbox = async () => {
        try {
            const data = await api(searchEntity, { entity: 'cashbox', filter: { _id: initialCashboxId } })
            if (data.count !== 1) return toast({ message: 'Kassa tapilmadi.' })
            setFormData(data.data[0])
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        if (initialCashboxId) fetchCashbox()
        else setFormData(initialValue)
    }, [initialCashboxId])

    return <Modal title="Kassa detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent className="ion-padding">
            <IonRow>
            <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.name,
                            name: 'name'
                        }}
                        label="Ad"
                    />
                </IonCol>
            </IonRow>
        
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButton disabled={isLoading} onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}