import Auth from "./Auth";
import BuyProductFromSupplier from "./BuyProductFromSupplier";
import Campaigns from "./Campaigns";
import Cashboxes from "./Cashboxes";
import Categories from "./Categories";
import Couriers from "./Couriers";
import Home from "./Home";
import News from "./News";
import Orders from "./Orders";
import Payments from "./Payments";
import Products from "./Products";
import Settings from "./Settings";
import Stocks from "./Stocks";
import Suppliers from "./Suppliers";
import Users from "./Users";
import Warehouses from "./Warehouses";
import SaleProductToSupplier from "./SaleProductToSupplier";
import ExtraPaymentCheckout from "./ExtraPaymentCheckout";
import CashboxCheckout from "./CashboxCheckout";
import CashboxSupplierAccounting from "./CashboxSupplierAccounting";
import Tickets from './Tickets'
import Referrer from "./Referrer";
export default [
    {
        name: 'home',
        path: '/home',
        component: Home
    },
    {
        name: 'products',
        path: '/products',
        component: Products
    },
    {
        name: 'orders',
        path: '/orders',
        component: Orders
    },
    {
        name: 'couriers',
        path: '/couriers',
        component: Couriers
    },
    {
        name: 'warehouses',
        path: '/warehouses',
        component: Warehouses
    },
    {
        name: 'settings',
        path: '/settings',
        component: Settings
    },
    {
        name: 'payments',
        path: '/payments',
        component: Payments
    },
    {
        name: 'buy-product-from-supplier',
        path: '/buy-product-from-supplier',
        component: BuyProductFromSupplier
    },
    {
        name: 'sale-product-to-supplier',
        path: '/sale-product-to-supplier',
        component: SaleProductToSupplier
    },
    {
        name: 'auth',
        path: '/auth',
        component: Auth
    },
    {
        name: 'categories',
        path: '/categories',
        component: Categories
    },
    {
        name: 'news',
        path: '/news',
        component: News
    },
    {
        name: 'campaigns',
        path: '/campaigns',
        component: Campaigns
    },
    {
        name: 'users',
        path: '/users',
        component: Users
    },
    {
        name: 'suppliers',
        path: '/suppliers',
        component: Suppliers
    },
    {
        name: 'cashboxes',
        path: '/cashboxes',
        component: Cashboxes
    },
    {
        name: 'stocks',
        path: '/stocks',
        component: Stocks
    },
    {
        name: 'extra-payment',
        path: '/extra-payment',
        component: ExtraPaymentCheckout
    },
    {
        name: 'cashbox-checkout',
        path: '/cashbox-checkout',
        component: CashboxCheckout
    },
    {
        name: 'tickets',
        path: '/tickets',
        component: Tickets,
    },
    {
        name: 'cashboxSupplierAccounting',
        path: '/cashbox-supplier-accounting',
        component: CashboxSupplierAccounting
    },
    {
        name: 'referrer',
        path: '/referrer',
        component: Referrer
    },
]
