import { useContext, useEffect, useState } from "react"
import { AppContext } from "../../App"
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { searchEntity } from "../../services";
import { dateTime, price } from "../../utils/format";
import './styles.css'

export default function Receipt({ initialOrderId, isPrinting, setPrinting }) {
    // TODO:
    // Receipt App.js de routingden ayri yazilmalidir. 
    const [order, setOrder] = useState(null);
    const [warehouse, setWarehouse] = useState(null);
    const { setLoading, warehouses } = useContext(AppContext);
    const [api] = useApi();
    const [toast] = useToast();

    const fetchOrder = async () => {
        setLoading(true)
        try {
            const data = await api(searchEntity, {
                entity: 'order',
                filter: { _id: initialOrderId },
                populate: {
                    path: 'cart',
                    populate: {
                        path: '_product',
                        model: 'Product'
                    }
                }
            })
            if (data.count !== 1) return toast({ message: 'Sifariş tapilmadi.' })

            const o = data.data[0]

            const warehouse = warehouses.find(w => w._id === o._warehouse);
            setWarehouse(warehouse)
            setOrder(o)

        } catch (error) {
            console.log(error.message)
        }
        setLoading(false)
    }


    useEffect(() => {
        if (isPrinting) fetchOrder()
        return () => setPrinting(false)
    }, [isPrinting])

    useEffect(() => {
        if (order) window.print()
    }, [order])

    if (!order) return <div id="Receipt">Sifaris tapilmadi</div>

    return <div id="Receipt">
        <div className="Receipt__header">
            <p className="logo">tezgel</p>
            <p>Unvan: {warehouse.name}</p>
            <p>Tel: {warehouse.phoneNumber}</p>
            <div className="ion-text-end">
                <p>Tarix: {dateTime(order.createdAt)}</p>
            </div>
        </div>
        <div className="Receipt__content">
            <div className="Receipt__content__address">
                <p>Ismail Aras</p>
                <p>Başlıq: {order.address.name}</p>
                <p>Unvan: {order.address.directions}</p>
                <p>Tel: {order.address.phoneNumber}</p>
            </div>
            <table className="Receipt__content__table">
                <thead>
                    <tr>
                        <th>Ədəd</th>
                        <th>Məhsullar</th>
                        <th className="ion-text-end">Qiymət</th>
                        <th className="ion-text-end">Məbləğ</th>
                    </tr>
                </thead>
                <tbody>
                    {order.cart.map(c => <tr key={c._id}>
                        <td>{c.qty}</td>
                        <td style={{ maxWidth: 150 }}>{c._product.shortName}</td>
                        <td className="ion-text-end">{price(c._product.price)}</td>
                        <td className="ion-text-end">{price(c._product.price * c.qty)}</td>
                    </tr>)}
                </tbody>
            </table>
            <div className="Receipt__content_payment-details">
                <p>Səbət Toplamı: {price(order.payment.cartCost)}</p>
                <p>Çatdırılma Məbləği: {price(order.payment.deliveryCost)}</p>
                <p><strong>Toplam Məbləğ: {price(order.payment.totalCost)}</strong></p>
            </div>
            <p>Qeydlər: {order.note}</p>
            <p>Qapıya Burax: {order.leftDoor ? 'Bəli' : 'Xeyir'}</p>
            <p>Zəngi Basma: {order.dontRing ? 'Bəli' : 'Xeyir'}</p>
                      
        </div>
        <div className="Receipt__footer">
            {order.isPaid ? 'Sənəd onlayn edilib.' : 'Sənəd təhvil zamanı alınmalıdır.'}
        </div>  
    </div>
}
