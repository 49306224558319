import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonContent, IonFooter, IonIcon, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { caretDown, trash } from "ionicons/icons";
import { useEffect, useState } from "react";
import { DataTable, Header, Input, TableForm } from "../../components";
import './styles.css'
import uniqid from 'uniqid';
import useToast from "../../hooks/useToast";
import { buyProductFromSupplier } from "../../services";
import { price } from "../../utils/format";
import useApi from "../../hooks/useApi";
import { useHistory } from "react-router";

const initialValue = {
    product: '',
    supplier: '',
    warehouse: '',
    cashbox: '',
    buyPrice: 0,
    buyDiscount: 0,
    buyAmount: 0,
    salePrice: 0,
    saleDiscount: 0,
    saleAmount: 0,
    buyToSellPrice: 0,
    qty: 1
}


const columns = [
    {
        label: 'Ad',
        name: 'name',
        useSearch: true
    }
]

export default function BuyProductFromSupplier() {
    const [isProductsTableOpen, setProductsTableOpen] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [selectedCashboxes, setSelectedCashboxes] = useState([]);
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);
    const [api] = useApi()
    const [toast] = useToast();
    const history = useHistory();
    const [rows, setRows] = useState([]);


    const addNewRow = () => {
        if (selectedProducts.length === 0
            || selectedCashboxes === 0
            || selectedSuppliers === 0
            || selectedWarehouses === 0)
            return toast({ message: 'Bütün xanalar seçilməlidir.' })
        const product = selectedProducts[0];

        if (rows.map(r => r.product._id).includes(product._id))
            return toast({ message: 'Seçilən məhsul əlavə edilib.' })

        const supplier = selectedSuppliers[0];
        const warehouse = selectedWarehouses[0];
        const cashbox = selectedCashboxes[0];

        setRows(prev => [
            ...prev,
            {
                ...initialValue,
                product,
                supplier,
                warehouse,
                cashbox,
                id: uniqid()
            }
        ])

    }

    const tables = [
        {
            entity: 'product',
            title: 'Məhsul seç',
            onSelectedItemsChanged: setSelectedProducts,
            columns
        },
        {
            entity: 'supplier',
            title: 'Tədarükçü seç',
            onSelectedItemsChanged: setSelectedSuppliers,
            columns
        },
        {
            entity: 'cashbox',
            title: 'Kassa seç',
            onSelectedItemsChanged: setSelectedCashboxes,
            columns
        },
        {
            entity: 'warehouse',
            title: 'Depo seç',
            onSelectedItemsChanged: setSelectedWarehouses,
            columns
        }
    ]

    const removeRow = id => {
        setRows(prev => prev.filter(p => p.id !== id));
    }

    const getTotalBuyAmount = () => {
        return rows.map(r => r.buyAmount).reduce((a, b) => a + b, 0)
    }
    const getTotalSaleAmount = () => {
        return rows.map(r => r.saleAmount).reduce((a, b) => a + b, 0)
    }
    const onIonChange = e => {
        let { name: nameDotId, value } = e.target;
        value = Number(value)
        const [name, id] = nameDotId.split('.');
        setRows(prev => prev.map((p, i) => {
            if (i == id) {
                p[name] = value;
                if (name === 'qty' || name === 'buyPrice' || name === 'buyDiscount')
                    p.buyAmount = (p.buyPrice + p.buyDiscount * p.buyPrice / 100) * p.qty
                if (name === 'qty' || name === 'salePrice' || name === 'saleDiscount')
                    p.saleAmount = (p.salePrice + p.saleDiscount * p.salePrice / 100) * p.qty
                if (name === 'buyToSellPrice') {
                    p.salePrice = p.buyPrice + (p.buyPrice * p.buyToSellPrice / 100)
                }
            }
            return p
        }))
    }

    const onSubmit = async () => {
        try {
            await api(buyProductFromSupplier, { 
                rows, 
                totalBuyAmount: getTotalBuyAmount(),
                totalSaleAmount: getTotalSaleAmount()
            })
            history.push('/stocks')
        } catch (error) {

        }
    }

    return <IonPage id="BuyProductFromSupplier">
        <Header title={"Məhsulların Alınması"} />

        <IonContent>
            <IonRow>
                {tables.map((t, i) => <IonCol key={i} sizeMd={3}>
                    <IonCard className="table-card">
                        {/* <IonButton fill="clear">
                                <IonIcon icon={caretDown}/>
                            </IonButton> */}
                        <IonCardHeader>
                            <IonCardTitle>{t.title}</IonCardTitle>
                        </IonCardHeader>
                        <DataTable
                            getDataOnSelect
                            showButton={false}
                            buttons={[]}
                            hideRefresh
                            isRefetching={false}
                            {...t}
                        />
                    </IonCard>
                </IonCol>
                )}
                <IonCol size={12}>
                    {rows.map((f, i) => <IonCard key={f.id}>
                        <IonCardContent>
                            <IonRow>
                                <IonCol size={1}>
                                    <strong>№{i + 1}</strong>
                                </IonCol>
                                <IonCol size={2}>
                                    <strong>M</strong> {f.product.name}
                                </IonCol>
                                <IonCol size={2}>
                                    <strong>T</strong> {f.supplier?.name}
                                </IonCol>
                                <IonCol size={2}>
                                    <strong>K</strong> {f.cashbox.name}
                                </IonCol>
                                <IonCol size={2}>
                                    <strong>D</strong> {f.warehouse?.name}
                                </IonCol>
                                <IonCol size={2} className="ion-text-end">
                                    <IonButton onClick={() => removeRow(f.id)} color="danger" size="small" fill="clear" className="ion-no-margin">
                                        <IonIcon slot="icon-only" icon={trash} />
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size={1}>
                                    <Input
                                        label={'Say'}
                                        inputProps={{
                                            placeHolder: 'ƏD',
                                            name: 'qty.' + i,
                                            value: f.qty,
                                            onIonChange
                                        }}
                                    />
                                </IonCol>
                                <IonCol size={2}>
                                    <Input
                                        label={'Alış Qiyməti'}
                                        inputProps={{
                                            placeHolder: 'Misal: 1.00',
                                            name: 'buyPrice.' + i,
                                            value: f.buyPrice,
                                            onIonChange,
                                            type: 'number'
                                        }}
                                    />
                                </IonCol>
                                <IonCol size={1}>
                                    <Input
                                        label={'AL ±%'}
                                        inputProps={{
                                            placeHolder: '20.00',
                                            name: 'buyDiscount.' + i,
                                            value: f.buyDiscount,
                                            onIonChange,
                                            type: 'number'
                                        }}
                                    />
                                </IonCol>
                                <IonCol size={2}>
                                    <Input
                                        label={'Alış Məbləği'}
                                        inputProps={{
                                            placeHolder: 'Misal: 1.00',
                                            name: 'buyAmount.' + i,
                                            value: f.buyAmount,
                                            onIonChange,
                                            readOnly: true,
                                        }}
                                    />
                                </IonCol>

                                <IonCol size={1}>
                                    <Input
                                        label={'>>> ±%'}
                                        inputProps={{
                                            placeHolder: '20.00',
                                            name: 'buyToSellPrice.' + i,
                                            value: f.buyToSellPrice,
                                            onIonChange,
                                            type: 'number'
                                        }}
                                    />
                                </IonCol>
                                <IonCol size={2}>
                                    <Input
                                        label={'Satış Qiyməti'}
                                        inputProps={{
                                            placeHolder: 'Misal: 1.00',
                                            name: 'salePrice.' + i,
                                            value: f.salePrice,
                                            onIonChange,
                                            type: 'number'
                                        }}
                                    />
                                </IonCol>
                                <IonCol size={1}>
                                    <Input
                                        label={'SA ±%'}
                                        inputProps={{
                                            placeHolder: '20.00',
                                            name: 'saleDiscount.' + i,
                                            value: f.saleDiscount,
                                            onIonChange,
                                            type: 'number'
                                        }}
                                    />
                                </IonCol>

                                <IonCol size={2}>
                                    <Input
                                        label={'Satış Məbləği'}
                                        inputProps={{
                                            placeHolder: 'Misal: 1.00',
                                            name: 'saleAmount.' + i,
                                            readOnly: true,
                                            value: f.saleAmount,
                                            onIonChange
                                        }}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>)}
                </IonCol>
            </IonRow>
        </IonContent>
        <IonFooter style={{ backgroundColor: 'var(--ion-color-light)' }}>
            <IonToolbar>
                <IonTitle>
                    Cəmi: ALM {price(getTotalBuyAmount())} | SAM {price(getTotalSaleAmount())}
                </IonTitle>
                <IonButtons slot="end">
                    <IonButton fill="solid" onClick={addNewRow}>
                        Yeni məhsul artır
                    </IonButton>
                    <IonButton onClick={onSubmit} fill="solid" color="primary">
                        Yadda saxla
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    </IonPage>
}