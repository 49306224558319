import {IonButton, IonContent, IonSelectOption} from "@ionic/react";
import {useState} from "react";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import {changeDeliveryStateEntity} from "../../services";

import Modal from "../Modal";
import Select from "../Select";


export default function ChangeDeliveryState({ onSuccess, states, entity, isOpen, setOpen, entityIds, ...rest }) {
    const [api] = useApi()
    const [toast] = useToast()
    const [state, setState] = useState('');

    const onSubmit = async () => {
        console.log("entityIds",entityIds)
        try {
            await api(changeDeliveryStateEntity, {deliveryState:state, entity, entityIds:entityIds});
            setState('')
            onSuccess()
            setOpen(false)
        } catch (error) {
            
        }
    }

    return <Modal title="Kuryer Status Dəyiş"
        isOpen={isOpen}
        setOpen={setOpen}
        initialBreakpoint={0.3}
        breakpoints={[0.3]}
        {...rest}>

        <IonContent className="ion-padding">
            <Select
                selectProps={{
                    value: state,
                    name: 'state',
                    onIonChange: e => setState(e.detail.value)
                }}
                label="Yeni statusu seçin"
            >
                {states.map(s => (<IonSelectOption disabled={s.disabled} key={s.name} value={s.name}>
                    {s.translation}
                </IonSelectOption>))}
            </Select>
            <IonButton style={{height: '50px', margin: 0, marginTop: 10}} onClick={onSubmit} expand="block">
                Təsdiqlə
            </IonButton>
        </IonContent>

    </Modal>
}