import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonContent, IonFooter, IonIcon, IonPage, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { caretDown, trash } from "ionicons/icons";
import { useEffect, useState } from "react";
import { DataTable, Header, Input, TableForm } from "../../components";
import uniqid from 'uniqid';
import useToast from "../../hooks/useToast";
import { cashboxCheckout, createExtraPayment } from "../../services";
import { price } from "../../utils/format";
import useApi from "../../hooks/useApi";
import { useHistory } from "react-router";

const initialValue = {
    supplier: '',
    cashbox: '',
    amount: 0,
    comment: '',
}

export default function CashboxCheckout() {
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [selectedCashboxes, setSelectedCashboxes] = useState([]);
    const [api] = useApi()
    const history = useHistory();
    const [rows, setRows] = useState([]);


    const addNewRow = () => {
        const supplier = selectedSuppliers[0];
        const cashbox = selectedCashboxes[0];

        setRows(prev => [
            ...prev,
            {
                ...initialValue,
                supplier,
                cashbox,
                id: uniqid()
            }
        ])
    }

    const tables = [
        
        {
            size: 3,
            entity: 'cashbox',
            title: 'Kassa seç',
            onSelectedItemsChanged: setSelectedCashboxes,
            columns: [{
                label: 'Ad',
                name: 'name',
                useSearch: true
            }]
        },
        {
            size: 3,
            entity: 'supplier',
            title: 'Tədarükçü seç',
            onSelectedItemsChanged: setSelectedSuppliers,
            columns: [{
                label: 'Ad',
                name: 'name',
                useSearch: true
            }]
        },
    ]

    const removeRow = id => {
        setRows(prev => prev.filter(p => p.id !== id));
    }

    const getTotalAmount = () => {
        return rows.map(r => Number(r.amount)).reduce((a, b) => a + b, 0)
    }
    const onIonChange = e => {
        let { name: nameDotId, value } = e.target;
        const [name, id] = nameDotId.split('.');
        setRows(prev => prev.map((p, i) => {
            if (i == id) {
                p[name] = value;
            }
            return p
        }))
    }

    const onSubmit = async () => {
        try {
            await api(cashboxCheckout, { rows })
            history.push('/payments')
        } catch (error) {

        }
    }

    return <IonPage id="BuyProductFromSupplier">
        <Header title={"Kassa Məxaric Orderi"} />

        <IonContent>
            <IonRow>
                {tables.map((t, i) => <IonCol key={i} sizeMd={t.size}>
                    <IonCard className="table-card">
                        {/* <IonButton fill="clear">
                                <IonIcon icon={caretDown}/>
                            </IonButton> */}
                        <IonCardHeader>
                            <IonCardTitle>{t.title}</IonCardTitle>
                        </IonCardHeader>
                        <DataTable
                            getDataOnSelect
                            showButton={false}
                            buttons={[]}
                            hideRefresh
                            isRefetching={false}
                            {...t}
                        />
                    </IonCard>
                </IonCol>
                )}
                <IonCol size={12}>
                    {rows.map((f, i) => <IonCard key={f.id}>
                        <IonCardContent>
                            <IonRow>
                                <IonCol size={6}>
                                    <h1>№{i + 1}</h1>
                                </IonCol>
                                <IonCol size={6} className="ion-text-end">
                                    <IonButton onClick={() => removeRow(f.id)} color="danger" size="small" className="ion-no-margin">
                                        <IonIcon slot="icon-only" icon={trash} />
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size={3}>
                                    <strong>Tədarükcü:</strong> {f.supplier?.name}
                                </IonCol>
                                <IonCol size={3}>
                                    <strong>Kassa:</strong> {f.cashbox.name}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size={2}>
                                    <Input
                                        label={'Məbləğ'}
                                        inputProps={{
                                            placeHolder: 'Misal: 1.00',
                                            name: 'amount.' + i,
                                            readOnly: false,
                                            value: f.amount,
                                            onIonChange
                                        }}
                                    />
                                </IonCol>
                                <IonCol size={5}>
                                    <Input
                                        label={'Qeydlər'}
                                        inputProps={{
                                            name: 'comment.' + i,
                                            readOnly: false,
                                            value: f.comment,
                                            onIonChange
                                        }}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>)}
                </IonCol>
            </IonRow>
        </IonContent>
        <IonFooter style={{ backgroundColor: 'var(--ion-color-light)' }}>
            <IonToolbar>
                <IonTitle>
                    Cəmi: {price(getTotalAmount())}
                </IonTitle>
                <IonButtons slot="end">
                    <IonButton fill="solid" onClick={addNewRow}>
                        Yeni order artır
                    </IonButton>
                    <IonButton onClick={onSubmit} fill="solid" color="primary">
                        Yadda saxla
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    </IonPage>
}