import { IonItem, IonInput, IonLabel } from "@ionic/react";
import './styles.css'

export default function Input({label, inputProps, ...rest}) {

    return <IonItem lines="none" id="Input" {...rest}>
        <IonLabel position="stacked">
            {label}
        </IonLabel>
        <IonInput {...inputProps}/>
    </IonItem>
}