import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton } from "@ionic/react";
import { CouriersTable, DataTable } from "../../components";
import Header from "../../components/Header";
import courierStates from "../../data/courierStates";
import useAuthRequired from "../../hooks/useAuthRequired";
import { dateTime, price } from "../../utils/format";


export default function Couriers({ }) {
    useAuthRequired()


    return <IonPage id="Couriers">
        <Header title="Kuryerlər" />
        <IonContent className="ion-padding">
            <CouriersTable />
        </IonContent>

    </IonPage>
}


/* 
server {
        listen 80;
        listen [::]:80;

        root /var/www/TezgelAdminServer;

        server_name admin-api.tezgel.az www.admin-api.tezgel.az;

        location / {
            proxy_pass http://localhost:3001;
            proxy_http_version 1.1;
            proxy_set_header Upgrade $http_upgrade;
            proxy_set_header Connection 'upgrade';
            proxy_set_header Host $host;
            proxy_cache_bypass $http_upgrade;
        }
}
*/