import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton } from "@ionic/react";
import { useState } from "react";
import { DataTable, Header, CashboxDetail } from "../../components";
import useAuthRequired from "../../hooks/useAuthRequired";
import { price } from "../../utils/format";

const columns = [
    {
        label: 'Ad',
        name: 'name',
        useSearch: true
    },
    {
        label: 'Balans',
        name: 'wallet',
        customFunc: data => <span>{price(data)}</span>
    },
]

export default function Cashboxes() {
    useAuthRequired()
    const [isRefetching, setRefetching] = useState(false);
    const [selectedCashboxes, setSelectedCashboxes] = useState([]);
    const [isCashboxDetailOpen, setCashboxDetailOpen] = useState(false)
    function onSelectedItemsChanged(items) {
        setSelectedCashboxes(items)
    }

    const tableButtons = [
        {
            label: 'Düzəliş et',
            onClick: e => setCashboxDetailOpen(true),
            disableCondition: selectedCashboxes.length !== 1
        }
    ]

    return <IonPage id="Cashboxes">
        <Header title="Kassalar"  right_buttons={[{
            label: 'Kassa artır',
            isDisabled: selectedCashboxes.length > 0,
            onClick: () => setCashboxDetailOpen(true)
        }]} />
        <IonContent className="ion-padding">
            <DataTable
                columns={columns}
                entity={'cashbox'}
                onSelectedItemsChanged={onSelectedItemsChanged}
                buttons={tableButtons}
                isRefetching={isRefetching}
                setRefetching={setRefetching}
            />
        </IonContent>
        <CashboxDetail
            onDidDismiss={() => setRefetching(true)}
            isOpen={isCashboxDetailOpen}
            setOpen={setCashboxDetailOpen}
            initialCashboxId={selectedCashboxes.length === 1 && selectedCashboxes[0]}
        />
    </IonPage>
}