import { IonPage, IonContent } from "@ionic/react";
import { WarehousesTable } from "../../components";
import Header from "../../components/Header";

import useAuthRequired from "../../hooks/useAuthRequired";


export default function Warehouses() {
    useAuthRequired()
 
    return <IonPage id="Warehouses">
        <Header title="Depo, Mağaza və Restoranlar" />
        <IonContent className="ion-padding">
           <WarehousesTable/>
        </IonContent>
       
    </IonPage>
}