import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonCard,
  IonRow,
  IonCol,
  IonMenuButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonInput,
  IonButton,
} from '@ionic/react'
import { addOutline, attachOutline, sendOutline } from 'ionicons/icons';
import { useCallback, useEffect, useRef, useState } from 'react'
import useApi from '../../hooks/useApi';
import { sendMessage } from '../../services/index.js';

import { date } from '../../utils/format';
import './style.css'


const TicketMessages = ({
  setSelectedTicket,
  selectedTicket,
  isOpenTicketModal,
  setisOpenTicketModal,
}) => {
  const [api] = useApi();

  const [message, setMessage] = useState("")

  const labelRef = useRef()

  const handleSenderMessage = useCallback(

    async () => {
      try {
        const { nM } = await api(sendMessage, { newMessage: message, _ticket: selectedTicket });
        setSelectedTicket(prev => ({ ...prev, messages: [...prev.messages, nM] }))
        setMessage('')

      } catch (error) {
        console.log(error.message)
      }
    }
    , [api, message, selectedTicket],
  )







  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    messagesEndRef.current?.scrollToBottom()
  }

  useEffect(() => {
    scrollToBottom()
  }, [handleSenderMessage])



  console.log(message)


  return (

    <IonContent id='message-section'>
      <IonContent ref={messagesEndRef}>

        <IonRow>
          <IonCol size='12'>
            <IonCard className='message-date'>{date(selectedTicket.createdAt)}</IonCard>
          </IonCol>
        </IonRow>

        {
          selectedTicket.messages.map((m, i) => (
            <IonRow className={m.isSelf ? 'user-row' : 'service-row'} key={i} >
              <IonCol size='12'>
                <IonItem>
                  <IonCard className='message-ballon'>
                    {m.text}
                    <span className='message-ballon-date' >
                      {date(m.createdAt)}
                    </span>
                  </IonCard>
                </IonItem>
              </IonCol>
            </IonRow>
          ))
        }












      </IonContent>


      {selectedTicket &&
        <IonCard className='attachment'>
          <IonButton className='attach-file' onClick={() => labelRef.current.click()}>
            <IonLabel>
              <IonIcon icon={attachOutline} />
            </IonLabel>
            <input type='file' id="file-input" ref={labelRef} />
          </IonButton>

          <IonInput placeholder='Mesajınızı buraya yazın...' type='text' value={message} onIonChange={(e) => setMessage(e.target.value)} />
          <IonButton onClick={() => handleSenderMessage()} type="submit">
            <IonIcon icon={sendOutline} />
          </IonButton>
        </IonCard>
      }


    </IonContent>

  )
}

export default TicketMessages