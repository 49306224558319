import {  IonBadge } from "@ionic/react";
import { useState } from "react";
import { ChangeState, DataTable } from "..";
import referrerStates from "../../data/referrerStates";
import { dateTime, price } from "../../utils/format";
import ReferrerDetail from "../ReferrerDetail";


const ReferrerBadge = ({ data }) => {
    const stateValue = referrerStates.find(o => o.name === data)?.translation
    return <IonBadge>{stateValue}</IonBadge>
}

const columns = [
    {
        label: 'Ad',
        name: 'name',
        useSearch: true
    },
    {
        label: 'Nömrə',
        name: 'phoneNumber',
        useSearch: true
    },
    {
        label: 'Faiz',
        name: 'percentage',
        useSearch: true
    },
    {
        label: 'Kod',
        name: 'referCode',
        useSearch: true
    },
    {
        label: 'İstifadə olunub dəfə',
        name: 'orders',
        customFunc: data => <span>{data.length}</span>
    },
    {
        label: 'Status',
        name: 'state',
        useSearch: false,
        customFunc: data => <ReferrerBadge data={data} />,
        filterOptions: {
            type: 'select',
            options: referrerStates
        }
    },
    {
        label: 'Tarix',
        name: 'createdAt',
        customFunc: data => <span>{dateTime(data)}</span>
    }
]

export default function ReferrerTable({onSelectedItemsChangedEvent, ...rest }) {
    const [isReferrerDetailOpen, setReferrerDetailOpen] = useState(false);
    const [selectedReferrers, setSelectedReferrers] = useState([]);
    const [isRefetching, setRefetching] = useState(false);
    const [isChangeStateOpen, setChangeStateOpen] = useState(false);

    function onSelectedItemsChanged(items) {
        setSelectedReferrers(items);
        onSelectedItemsChangedEvent && onSelectedItemsChangedEvent(items)
    }
    function onStateSuccess() {
        setRefetching(true)
    }
    const tableButtons = [
        {
            label: 'Düzəliş et',
            onClick: e => setReferrerDetailOpen(true),
            disableCondition: selectedReferrers.length !== 1
        },
        {
            label: 'Status Dəyiş',
            onClick: e => setChangeStateOpen(true),
            disableCondition: selectedReferrers.length === 0
        }
    ]
    return <>
        <DataTable
            columns={columns}
            entity={'referrer'}
            isRefetching={isRefetching}
            setRefetching={setRefetching}
            onSelectedItemsChanged={onSelectedItemsChanged}
            buttons={tableButtons}
            onAddButtonClick={() => setReferrerDetailOpen(true)}
            {...rest}
        />
        <ReferrerDetail
            onDidDismiss={() => setRefetching(true)}
            isOpen={isReferrerDetailOpen}
            setOpen={setReferrerDetailOpen}
            initialReferrerId={selectedReferrers.length === 1 && selectedReferrers[0]}
        />
        <ChangeState
            isOpen={isChangeStateOpen}
            setOpen={setChangeStateOpen}
            entity={'referrer'}
            onSuccess={onStateSuccess}
            states={referrerStates}
            entityIds={selectedReferrers}
        />
    </>
}


/* 
server {
        listen 80;
        listen [::]:80;

        root /var/www/TezgelAdminServer;

        server_name admin-api.tezgel.az www.admin-api.tezgel.az;

        location / {
            proxy_pass http://localhost:3001;
            proxy_http_version 1.1;
            proxy_set_header Upgrade $http_upgrade;
            proxy_set_header Connection 'upgrade';
            proxy_set_header Host $host;
            proxy_cache_bypass $http_upgrade;
        }
}
*/