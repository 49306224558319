import { IonButton, IonCard, IonCol, IonIcon, IonImg, IonItem, IonLabel, IonRow, IonThumbnail } from "@ionic/react";
import { add } from "ionicons/icons";
import { CartTool } from "..";
import { price } from "../../utils/format";
import Image from "../Image";
import './styles.css'


const ProductItem = ({ isVertical, product, cart, setCart }) => {
    // isHorizontal yazilmalidir
    // #F9F8FC

    return isVertical
        ? <div id="ProductItem">
            <CartTool cart={cart} product={product} setCart={setCart} />
            <IonCard className='product-card'>
                <Image src={product.picUrls[0]} alt={"photo"} />
            </IonCard>

            <p className='product-price'>
                {product?.struckPrice && <s>{price(product.struckPrice)}</s>} <span>{price(product.price)}</span>
            </p>
            <p className='product-title'>{product.shortName}</p>
            <p className='product-sub-title'>{product.shortDescription}</p>

        </div>
        : <div id="ProductItem">
            <IonItem lines="none">
                <IonThumbnail>
                    <Image src={product.picUrls[0]} alt={"photo"} />
                </IonThumbnail>
                <IonLabel className="ion-text-wrap">
                    <p className='product-title'>{product.shortName}</p>
                    <p className='product-sub-title'>{product.shortDescription}</p>
                    <p className='product-price'>
                        {product?.struckPrice && <s>{price(product.struckPrice)}</s>} <span>{price(product.price)}</span>
                    </p>
                </IonLabel>
                <CartTool cart={cart} product={product} isHorizontal setCart={setCart}/>
            </IonItem>
            

        </div>
}

ProductItem.defaultProps = {
    isVertical: true
}

export default ProductItem;