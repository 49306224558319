import { IonButton, IonButtons, IonToolbar } from "@ionic/react";
import { useHistory } from "react-router";
import './styles.css';

export default function Toolbox() {
    const history = useHistory();
    return  <IonToolbar id="Toolbox" color='light' mode="ios">
    {/* <IonButtons slot="start">
        <IonButton color='primary'>
            Yeni sənədlər
        </IonButton>
        <IonButton color='primary'>
            Sənədlər toplusu
        </IonButton>
        <IonButton color='primary'>
            Hesablar
        </IonButton>
    </IonButtons> */}
    <IonButtons slot="end">
        <IonButton color='primary' onClick={() => history.push('/buy-product-from-supplier')}>
            MA
        </IonButton>
        <IonButton color='primary' onClick={() => history.push('/sale-product-to-supplier')}>
            MQ
        </IonButton>
        <IonButton color='primary' onClick={() => history.push('/extra-payment')}>
            SX
        </IonButton>
        <IonButton color='primary' onClick={() => history.push('/cashbox-checkout')}>
            KMO
        </IonButton>
        <IonButton color='primary' onClick={() => history.push('/cashbox-supplier-accounting')}>
            KQH
        </IonButton>
    </IonButtons>
</IonToolbar>
}