import { IonButton, IonCard, IonIcon } from "@ionic/react";
import { add, remove } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import useToast from "../../hooks/useToast";
import { updateCart } from "../../services";
import './styles.css';
import uniqid from 'uniqid';

const CartTool = ({ cart, setCart, product, isHorizontal }) => {
    const [isInCart, setInCart] = useState(false);
    const [qty, setQty] = useState(0);
    const [present] = useToast();

    useEffect(() => {
        const cartItem = cart?.find(cItem => cItem._product._id === product._id);
        if (cartItem) {
            setInCart(true);
            setQty(cartItem.qty)
        }
        return () => {
            setInCart(false)
            setQty(0)
        }
    }, [cart])

    const addToCart = async () => {
        try {
            let newCart = [];
            const cartItem = cart?.find(cItem => cItem._product._id === product._id);
            if (cartItem) newCart = cart.map(cItem => {
                if (cItem._product._id === product._id) cItem.qty += 1;
                return cItem
            })
            else newCart = [...cart, {_product: product._id, qty: 1, _id: uniqid()}]
            setCart(await newCart)
        } catch (error) {
            present({message: 'Xəta baş verdi. Təkrar yoxlayın.'})
        }
    }

    const removeFromCart = async () => {
        try {
            let newCart = [];
            const cartItem = cart?.find(cItem => cItem._product._id === product._id);
            if (!cartItem || cartItem.qty === 0) return;

            newCart = cart.map(cItem => {
                if (cItem._product._id === product._id) cItem.qty -= 1;
                return cItem
            })
            setCart(await newCart)
        } catch (error) {
            present({message: 'Xəta baş verdi. Təkrar yoxlayın.'})
        }
    }

    return <div id="CartTool" style={{ transform: isHorizontal ? 'rotate(90deg)' : '', marginTop:isHorizontal ? 45 :0 }}>
        <IonCard onClick={addToCart} className="card increase"
            style={{
                borderBottomLeftRadius: (isInCart ? 0 : 8),
                borderBottomRightRadius: (isInCart ? 0 : 8)
            }}>
            <IonIcon src={add} color="primary" />
        </IonCard>
        {isInCart && <><IonCard className="card count" style={{ transform: isHorizontal ? 'rotate(270deg)' : '' }}>
            <span>{qty}</span>
        </IonCard>
        <IonCard onClick={removeFromCart} className="card decrease" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            <IonIcon style={{ transform: isHorizontal ? 'rotate(90deg)' : '' }} src={remove} color="primary" />
        </IonCard></>}
    </div>
}

export default CartTool;