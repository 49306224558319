import L from 'leaflet';
import { useEffect, useState } from 'react';

import * as EsriVector from 'esri-leaflet-vector'
import curPosIcon from '../../assets/images/current-position-icon.svg'

import './styles.css'
import { ESRI_API_KEY, ESRI_BASEMAP_ENUM } from '../../constants';
import * as esri from "esri-leaflet"

// https://developers.arcgis.com/dashboard/ login via google

export default function LeafletMap({
    position,
    setMap
}) {
    const [positionMarker, setPositionMarker] = useState(null);


    useEffect(() => {



        const tiles = EsriVector.vectorBasemapLayer(ESRI_BASEMAP_ENUM, {
            apiKey: ESRI_API_KEY
        })

        
        const m = L.map("map", {
            zoomControl: true,
            layers: [tiles],
            maxZoom: 18,
            minZoom: 6
        }).setView(position, 14);

        setTimeout(function () { m.invalidateSize() }, 800);

        const curPosIconObj = L.icon({
            iconUrl: curPosIcon,
            iconSize: [20, 20]
        })
        const currentPositionMarker = L.marker(position, { icon: curPosIconObj }).addTo(m);
        m.addLayer(currentPositionMarker)
        setPositionMarker(currentPositionMarker)
        setMap(m)
    }, [])
    
    useEffect(() => {
        if (position && positionMarker) {
            positionMarker.setLatLng(new L.LatLng(position[0], position[1]));
        }
    }, [position])


    return <div id="map"></div>
}
