import { IonButton, IonButtons, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { close } from 'ionicons/icons';
import './styles.css';

export default function Modal({ onDidDismiss, title, isOpen, setOpen, secondButton, children, ...rest }) {
    
    return <IonModal
        isOpen={isOpen}
        {...rest}
        // breakpoints={[1]}
        // initialBreakpoint={1}
        onDidDismiss={() => {
            onDidDismiss && onDidDismiss()
            setOpen(false)
        }}
    >
        <IonHeader className="ion-no-border">
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonButton slot="primary" onClick={() => setOpen(false)}>
                        <IonIcon src={close} slot="icon-only" />
                    </IonButton>
                </IonButtons>
                <IonTitle>{title}</IonTitle>
                {secondButton && <IonButtons slot="end">
                    <IonButton onClick={secondButton.onClick}>
                        <IonIcon src={secondButton.icon} slot="icon-only" />
                    </IonButton>
                </IonButtons>}
            </IonToolbar>
        </IonHeader>
        {children}
    </IonModal>
}