import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import { ChangeState, DataTable, OrderDetail, Receipt, SendNotification } from "..";
import Header from "../Header";
import Modal from "../Modal";
import orderStates from "../../data/orderStates";
import orderTypes from "../../data/orderTypes";
import useApi from "../../hooks/useApi";
import useAuthRequired from "../../hooks/useAuthRequired";
import useToast from "../../hooks/useToast";
import { getUser, searchEntity } from "../../services";
import { dateTime, price } from "../../utils/format";
import courierStates from "../../data/courierStates";
import deliveryStates from "../../data/deliveryStates";
import ChangeDeliveryState from "../ChangeDeliveryState";

const OrderBadge = ({ data }) => {
    const stateValue = orderStates.find(o => o.name === data)?.translation
    return <IonBadge>{stateValue}</IonBadge>
}
const DeliveryStateBadge = ({ data }) => {
    const stateValue = deliveryStates.find(o => o.name === data)?.translation
    return <IonBadge>{stateValue}</IonBadge>
}

const OrderTypeBadge = ({ data }) => {
    const stateValue = orderTypes.find(o => o.name === data)?.translation
    return <IonBadge>{stateValue}</IonBadge>
}

const columns = [
    {
        label: 'Nömrə',
        name: 'address.phoneNumber',
        useSearch: true
    },
    {
        label: 'Başlıq',
        name: 'address.name',
        useSearch: false
    },
    {
        label: 'Ünvan',
        name: 'address.directions',
        useSearch: true
    },
    {
        label: 'Kuryer',
        name: '_courier',
        customFunc: data => <div>{data?.name}({data?.phoneNumber})</div>,
        useSearch: true
    },
    {
        label: 'Status: ',
        name: 'state',
        useSearch: false,
        customFunc: data => <OrderBadge data={data} />,
        filterOptions: {
            type: 'select',
            options: orderStates
        }
    },
    {
        label: 'Kuryer status: ',
        name: 'deliveryState',
        useSearch: false,
        customFunc: data => <DeliveryStateBadge data={data} />,
        filterOptions: {
            type: 'select',
            options: deliveryStates
        }
    },
    {
        label: 'Növ: ',
        name: 'type',
        useSearch: false,
        customFunc: data => <OrderTypeBadge data={data} />,
        filterOptions: {
            type: 'select',
            options: orderTypes
        }
    },
    {
        label: 'Səbət',
        name: 'payment.cartCost',
        useSearch: false,
        customFunc: data => price(data)
    },
    {
        label: 'Çatdırılma',
        name: 'payment.deliveryCost',
        useSearch: false,
        customFunc: data => price(data)
    },
    {
        label: 'Toplam',
        name: 'payment.totalCost',
        useSearch: false,
        customFunc: data => price(data)
    },
    {
        label: 'Tarix',
        name: 'createdAt',
        customFunc: data => <span>{dateTime(data)}</span>
    }
]


const OrdersTable = ({ onSelectedItemsChangedEvent, additionalButtons, isRefetchingInitialValue, ...rest }) => {
    const [isOrderDetailOpen, setOrderDetailOpen] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [isChangeStateOpen, setChangeStateOpen] = useState(false);
    const [isChangeDeliveryStateOpen, setChangeDeliveryStateOpen] = useState(false);
    const [isReceiptPrinting, setReceiptPrinting] = useState(false);
    const [isNotifOpen, setNotifOpen] = useState(false);
    const [isRefetching, setRefetching] = useState(false);
    const [selectedOrderUsers, setSelectedOrderUsers] = useState([]);

    function onSelectedItemsChanged(items) {
        setSelectedOrders(items)
        onSelectedItemsChangedEvent && onSelectedItemsChangedEvent(items)

        const index = orderStates.findIndex(obj=>obj.name === 'on-way')
        if(items.length > 1) orderStates[index].disabled = true
        else orderStates[index].disabled = false
    }

    function onStateSuccess() {
        setRefetching(true)
    }

    useEffect(() => {
        setRefetching(!!isRefetchingInitialValue)
    }, [isRefetchingInitialValue])

    async function sendNotifcation() {
        try {
            const { data } = await searchEntity({
                entity: 'order',
                filter: {
                    _id: { $in: selectedOrders }
                },
                select: '_user'
            })
            setSelectedOrderUsers(data.map(d => d._user))
            setNotifOpen(true)
        } catch (error) {

        }
    }

    const tableButtons = [
        {
            label: 'Düzəliş et',
            onClick: e => setOrderDetailOpen(true),
            disableCondition: selectedOrders.length !== 1
        },
        {
            label: 'Status Dəyiş',
            onClick: e => setChangeStateOpen(true),
            disableCondition: selectedOrders.length === 0
        },
        {
            label: 'Kuryer Statusu Dəyiş',
            onClick: e => setChangeDeliveryStateOpen(true),
            disableCondition: selectedOrders.length === 0
        },
        {
            label: 'Çek çap et',
            onClick: e => setReceiptPrinting(true),
            disableCondition: selectedOrders.length === 0
        },
        {
            label: 'Notifikasiya',
            onClick: sendNotifcation,
            disableCondition: selectedOrders.length === 0
        },
        ...additionalButtons
    ]

    return <>
        <DataTable
            columns={columns}
            entity={'order'}
            isRefetching={isRefetching}
            setRefetching={setRefetching}
            onSelectedItemsChanged={onSelectedItemsChanged}
            buttons={tableButtons}
            populate="_courier"
            onAddButtonClick={() => setOrderDetailOpen(true)}
            {...rest}
        />
        <SendNotification
            isOpen={isNotifOpen}
            setOpen={setNotifOpen}
            userIds={selectedOrderUsers}
        />
        <OrderDetail
            onDidDismiss={() => setRefetching(true)}
            isOpen={isOrderDetailOpen}
            setOpen={setOrderDetailOpen}
            initialOrderId={selectedOrders.length === 1 && selectedOrders[0]}
        />
        <ChangeState
            isOpen={isChangeStateOpen}
            setOpen={setChangeStateOpen}
            entity={'order'}
            onSuccess={onStateSuccess}
            states={orderStates}
            entityIds={selectedOrders}
        />
        <ChangeDeliveryState
            isOpen={isChangeDeliveryStateOpen}
            setOpen={setChangeDeliveryStateOpen}
            entity={'order'}
            onSuccess={onStateSuccess}
            states={deliveryStates}
            entityIds={selectedOrders}
        />
        <Receipt
            initialOrderId={selectedOrders.length === 1 && selectedOrders[0]}
            isPrinting={isReceiptPrinting}
            setPrinting={setReceiptPrinting}
        />
    </>
}

OrdersTable.defaultProps = {
    additionalButtons: []
}

export default OrdersTable;
