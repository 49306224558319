import { IonButtons, IonHeader, IonButton, IonTitle, IonToolbar, IonBackButton, IonIcon, IonMenuButton } from '@ionic/react';
import { useContext } from 'react';
import { Logo, Toolbox } from '..';
import { AppContext } from '../../App';

import './styles.css';

const Header = ({ title, right_buttons, backButton, backButtonDefaultHref, children }) => {
    const {user} = useContext(AppContext)
    return <IonHeader id="Header">
        <IonToolbar>
            <IonButtons slot="start">
                {backButton
                    ? <IonBackButton defaultHref={backButtonDefaultHref || '/home'} text="Geri" />
                    : <IonMenuButton />}
            </IonButtons>
            <IonTitle>
                {title || <Logo />}
            </IonTitle>
            {right_buttons && right_buttons.map((b, i) => <IonButtons key={i} slot="end">
                <IonButton disabled={b.isDisabled} onClick={b.onClick} fill="clear">
                    {b.label}
                </IonButton>
            </IonButtons>)}
        </IonToolbar>
        {(user && user.role === 'admin') && <Toolbox/>}
        {children}
    </IonHeader>
}

export default Header;