import { IonPage, IonContent } from "@ionic/react";

import { OrdersTable } from "../../components";
import Header from "../../components/Header";

import useAuthRequired from "../../hooks/useAuthRequired";



export default function Orders() {
    useAuthRequired()

    return <IonPage id="Orders">
        <Header title="Sifarişlər" />
        <IonContent className="ion-padding">
            <OrdersTable/>
        </IonContent>
    </IonPage>
}