import { IonPage, IonContent } from "@ionic/react";
import ReferrerTable from "../../components/ReferrerTable";
import Header from "../../components/Header";
import useAuthRequired from "../../hooks/useAuthRequired";


export default function Referrer({ }) {
    useAuthRequired()


    return <IonPage id="Referrer">
        <Header title="Referanslar" />
        <IonContent className="ion-padding">
            <ReferrerTable />
        </IonContent>
    </IonPage>
}
