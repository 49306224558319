import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton, IonText } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import { ChangeState, DataTable, Header, StockDetail, Toolbox } from "../../components";
import useAuthRequired from "../../hooks/useAuthRequired";
import { dateTime, price } from "../../utils/format";



export default function Stocks() {
    useAuthRequired()
    const { user } = useContext(AppContext);
    const [isRefetching, setRefetching] = useState(false);
    const [selectedStocks, setSelectedStocks] = useState([]);
    const history = useHistory();
    function onSelectedItemsChanged(items) {
        setSelectedStocks(items)
    }

    const columns = [
        {
            label: 'Məhsul Adı',
            name: '_product.name',
            useSearch: true,
            filterOptions: {
                type: 'entity-field',
                name: '_product._id',
                entity: 'product'
            }
        },
        {
            label: 'Say',
            name: 'qty',
            useSearch: true,
        },
        {
            label: 'Alış Qiyməti',
            name: 'buyPrice',
            customFunc: data => <span>{price(data)}</span>,
            useSearch: true,
        },
        {
            label: 'ALIŞ ±%',
            name: 'buyDiscount',
            useSearch: true,
        },
        {
            label: 'Alış Məbləği',
            name: 'buyAmount',
            customFunc: data => <span>{price(data)}</span>,
            useSearch: true,
        },
        {
            label: 'Satış Qiyməti',
            name: 'salePrice',
            customFunc: data => <span>{price(data)}</span>,
            useSearch: true,
        },
        {
            label: 'SATIŞ ±%',
            name: 'saleDiscount',
            useSearch: true,
        },
        {
            label: 'Satış Məbləği',
            name: 'saleAmount',
            customFunc: data => <span>{price(data)}</span>,
            useSearch: true,
        },
        {
            label: 'Depo',
            name: '_warehouse.name',
            useSearch: true,
            filterOptions: {
                type: 'entity-field',
                name: '_warehouse._id',
                entity: 'warehouse'
            }
        },
        {
            label: 'Tədarükcü',
            name: '_supplier.name',
            useSearch: true,
            filterOptions: {
                type: 'entity-field',
                name: '_supplier._id',
                entity: 'supplier'
            }
        },
        {
            label: 'Kassa',
            name: '_cashbox.name',
            useSearch: true,
            filterOptions: {
                type: 'entity-field',
                name: '_cashbox._id',
                entity: 'cashbox'
            }
        },
        {
            label: 'Tarix',
            name: 'createdAt',
            customFunc: data => <span>{dateTime(data)}</span>
        }
    ]
    const tableButtons = [
        
    ]

    return <IonPage id="Stocks">
        <Header title="Stoklar" right_buttons={[{
            label: 'Stok artır',
            isDisabled: selectedStocks.length > 0,
            onClick: () => history.push('/buy-product-from-supplier')
        }]} >

        </Header>
        <IonContent className="ion-padding">
        <DataTable
                columns={columns}
                entity={'stock'}
                onSelectedItemsChanged={onSelectedItemsChanged}
                buttons={tableButtons}
                isRefetching={isRefetching}
                setRefetching={setRefetching}
                populate={'_cashbox _warehouse _supplier _product'}
            />
        </IonContent>

    </IonPage>
}