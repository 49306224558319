export default [
    {
        name: 'active',
        translation: 'Aktiv'
    },
    {
        name: 'busy',
        translation: 'Məşğul'
    },
    {
        name: 'fired',
        translation: 'İşləmir'
    }
]