import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonBadge, IonMenuButton, IonFab, IonFabButton, IonText, IonDatetime, IonRow, IonCol, IonSelectOption } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { ChangeState, DataTable, Header, Input, Select, Toolbox } from "../../components";
import useApi from "../../hooks/useApi";
import useAuthRequired from "../../hooks/useAuthRequired";
import { getCashboxAccountingViaDate, searchEntity } from "../../services";
import { dateTime, price } from "../../utils/format";
import './styles.css'

export default function CashboxSupplierAccounting() {
    useAuthRequired()
    const [data, setData] = useState(null);
    const [formDataFilter, setFormDataFilter] = useState({
        startAt: "2022-06-01",
        endAt: "2022-06-01",
        _cashbox: '',
        _supplier: ''
    })
    const [api] = useApi()
    const { cashboxes, suppliers, user } = useContext(AppContext);

    const onSubmit = async () => {
        try {
            const {data} = await api(getCashboxAccountingViaDate,formDataFilter)
            setData(data)

        } catch (error) {

        }
    }


    return <IonPage id="CashboxSupplierAccounting">
        <Header title="Kontragentlərlə Qarşılıqlı Hesablaşma" >
        </Header>
        <IonContent className="ion-padding">
            <IonRow>
                <IonCol>
                    <Input label={'Başlanğıc Tarixi'} inputProps={{
                        value: formDataFilter.startAt,
                        placeHolder: '2021-01-20',
                        onIonChange: e => setFormDataFilter({ ...formDataFilter, startAt:  e.target.value })
                    }} />
                </IonCol>
                <IonCol>
                    <Input label={'Bitiş Tarixi'} inputProps={{
                        value: formDataFilter.endAt,
                        placeHolder: '2021-01-20',
                        onIonChange: e => setFormDataFilter({ ...formDataFilter, endAt:  e.target.value })
                    }} />
                </IonCol>
                <IonCol>
                    <Select selectProps={{
                        onIonChange: e => setFormDataFilter({ ...formDataFilter, _cashbox: e.detail.value }),
                        value: formDataFilter._cashbox
                    }}
                        label="Kassa">
                        {cashboxes.map((w, i) => <IonSelectOption value={w._id} key={i}>
                            {w.name}
                        </IonSelectOption >)}
                    </Select>
                </IonCol>
                <IonCol>
                    <Select selectProps={{
                        onIonChange: e => setFormDataFilter({ ...formDataFilter, _supplier: e.detail.value }),
                        value: formDataFilter._supplier
                    }}
                        label="Tədarükcü">
                        {suppliers.map((w, i) => <IonSelectOption value={w._id} key={i}>
                            {w.name}
                        </IonSelectOption >)}
                    </Select>
                </IonCol>
                <IonCol>
                    <IonButton className="ion-no-margin" fill="outline" size="large" onClick={onSubmit}>
                        Axtar
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size={12}>
                    <table>
                        <thead>
                            <tr>
                                <th>İlk Borc</th>
                                <th>Son Borc</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data && <><td className="ion-text-end">{price(data?.startDebt)}</td>
                                <td className="ion-text-end">{price(data?.endDebt)}</td></>}
                        </tbody>
                    </table>
                </IonCol>
            </IonRow>
        </IonContent>
    </IonPage>
}