import { IonButton, IonContent, IonFooter, IonLabel, IonSelectOption, IonToolbar } from "@ionic/react";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { createSetting, searchEntity, updateSetting } from "../../services";
import Input from "../Input";
import Modal from "../Modal";
import Select from "../Select";

const valueTypes = [
    'string',
    'number',
    'object',
    'html'
]

const initialValue = {
    key: '',
    value: '',
    type: 'string'
}


const SettingsType = {
    "MAX_DISTANCE_IN_KM" : "Maksimum uzaqlıq",
    "OBJECT_TEST": "Obyekt Testi",
    "CONTACT_PAGE_CONTENT": "Əlaqə səhifəsi məzmunu",
    "TERMS_PAGE_CONTENT": "Şərtlər səhifəsi məzmunu",
    "FAQ_PAGE_CONTENT": "FAQ səhifəsi məzmunu",
    "ABOUTUS_PAGE_CONTENT": "Haqqımızda səhifəsi məzmunu",
    "PN_ALLOWED_ENTITIES": "Notifikasiyaya qəbul edilən varlıqlar",
    "ORDER_CREATED": "Sifariş yaradıldı",
    "ORDER_GETTING-READY": "Sifariş hazırlanır",
    "ORDER_ON-WAY": "Sifariş yoldadır",
    "ORDER_CANCELLED": "Sifariş iptal edildi",
    "ORDER_AT-ADDRESS": "Sifariş adresdədir",
    "ORDER_DELIVERED": "Sifariş təhvil verildi",
    "PAYMENT_TYPES": "Ödəmə tipləri",
    "TICKET_CATEGORIES": "Müraciət kategoriyaları",
    "ORDER_COURIER_COST_SETTINGS": "Sifariş kuryer maaliyyəti",
}



export default function SettingDetail({ isOpen, setOpen, initialSettingId, ...rest }) {
    const [formData, setFormData] = useState(initialValue);
    const [api] = useApi()
    const [toast] = useToast()

    const onSubmit = async () => {
        let formData1 = formData;
        switch (formData.type) {
            case 'object':
                formData1.value = JSON.parse(formData1.value)
                break;
            case 'number':
                formData1.value = Number(formData1.value)
                break;
            default:
                formData1.value = formData1.value
                break;
        }
        initialSettingId
            ? await api(updateSetting, formData1)
            : await api(createSetting, formData1)
        setOpen(false)
    }

    const onIonChange = e => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value })
    }

    const fetchSetting = async () => {
        try {
            const data = await api(searchEntity, { entity: 'setting', filter: { _id: initialSettingId } })
            if (data.count !== 1) return toast({ message: 'Tapilmadi.' })
            const newData = data.data[0];
            switch (newData.type) {
                case 'object':
                    newData.value = JSON.stringify(newData.value)
                    break;
                case 'number':
                    newData.value = Number(newData.value)
                    break;
                default:
                    newData.value = newData.value
                    break;
            }
            setFormData(newData)
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        if (initialSettingId) fetchSetting()
        else setFormData(initialValue)
    }, [initialSettingId])

    return <Modal title="Tənzim detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent className="ion-padding">
            <Input
                inputProps={{
                    onIonChange,
                    value: SettingsType[formData.key] ? SettingsType[formData.key] : formData.key,
                    name: 'key'
                }}
                label="Açar"
            />

            <Select selectProps={{
                onIonChange: e => setFormData({ ...formData, type: e.detail.value }),
                value: formData.type
            }}
                className='ion-margin-vertical'
                label="Dəyər növü">
                {valueTypes.map((w, i) => <IonSelectOption value={w} key={i}>
                    {w}
                </IonSelectOption >)}
            </Select>
            
            {formData.type !== 'html'
                ? <Input
                    inputProps={{
                        onIonChange,
                        value: formData.value,
                        name: 'value'
                    }}
                    label="Dəyər"
                />
                : <><IonLabel>Kontent</IonLabel><ReactQuill value={formData.value}
                onChange={e => setFormData({ ...formData, value: e })} /></>}
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButton onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}