import { IonApp, IonLoading, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'react-quill/dist/quill.snow.css';
import "react-datepicker/dist/react-datepicker.css";

import { YMaps } from 'react-yandex-maps';
/* Theme variables */
import './theme/variables.css';
import routes from './pages/routes';
import { InnerItem } from './components';
import { createContext, useEffect, useState } from 'react';
import useApi from './hooks/useApi';
import { getUser, searchEntity } from './services';
import { getValue, removeValue } from './utils/storage';
import { useInterval } from './hooks/useInterval';


setupIonicReact({
  mode: 'md'
});

export const AppContext = createContext();


const App = () => {
  const [isLoading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [referrer, setReferrer] = useState([]);
  const [settings, setSettings] = useState([]);
  const [cashboxes, setCashboxes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [user, setUser] = useState(null);
  const [api] = useApi();


  const logOut = () => {
    removeValue('@token')
    setUser(null);
    window.location = '/auth'
  }

  const fetchUser = async () => {
    try {
      const { user } = await getUser()
      setUser(user);
    } catch (error) {

    }
  }

  const fetchCategories = async () => {
    try {
      const { data } = await api(searchEntity, { entity: 'category', limit: 100 });
      setCategories(data)
    } catch (error) {
    }
  }

  const fetchSuppliers = async () => {
    try {
      const { data } = await api(searchEntity, { entity: 'supplier', limit: 100 });
      setSuppliers(data)
    } catch (error) {
    }
  }

  const fetchCashboxes = async () => {
    try {
      const { data } = await api(searchEntity, { entity: 'cashbox', limit: 100 });
      setCashboxes(data)
    } catch (error) {
    }
  }

  const fetchSettings = async () => {
    try {
      const { data } = await api(searchEntity, { entity: 'setting', limit: 100 });
      setSettings(data)
    } catch (error) {
    }
  }

  const fetchWarehouses = async () => {
    try {
      const { data } = await api(searchEntity, { entity: 'warehouse', limit: 100 });
      setWarehouses(data)
    } catch (error) {
    }
  }

  const fetchCouriers = async () => {
    try {
      const { data } = await api(searchEntity, { entity: 'courier', limit: 1000 });
      setCouriers(data)
    } catch (error) {

    }
  }
  
  const fetchReferrer = async () => {
    try {
      const { data } = await api(searchEntity, { entity: 'referrer', limit: 100, filter: {
        state: {$ne: 'passive'}
      } });
      setReferrer(data)
    } catch (error) {
    }
  }
 
  useEffect(() => {
    (async () => {
      const token = await getValue('@token');
      if (token) {
        fetchUser()
      }
    })()
  }, []);
  
  useInterval(fetchCouriers, 10000);
  
  useEffect(() => {
    if (user) {
      fetchWarehouses()
      fetchCouriers();
      fetchCategories()
      fetchSettings();
      fetchCashboxes();
      fetchSuppliers();
      fetchReferrer();
    } else {
      setWarehouses([])
      setCouriers([])
      setCategories([])
      setSettings([]);
      setCashboxes([]);
      setSuppliers([]);
      setReferrer([]);
    }
  }, [user])


  return (
    <IonApp>
      <YMaps query={{ lang: "tr_TR" }} >
        <AppContext.Provider value={{
          warehouses,
          setWarehouses,
          couriers,
          setCouriers,
          referrer,
          setReferrer,
          isLoading,
          setLoading,
          user,
          setUser,
          logOut,
          categories,
          settings,
          cashboxes,
          suppliers
        }}>

          <IonReactRouter>
            <IonSplitPane contentId="main">
              {user && <Menu />}
              <IonRouterOutlet id="main">
                {routes.map(r => {
                  return <Route
                    key={r.name}
                    path={r.path}
                    render={(props) => <InnerItem
                      {...props}
                      inner={r.component}
                    />}
                  />
                })}
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
          <IonLoading
            message={'Zəhmət olmasa, gözləyin...'}
            isOpen={isLoading}
          />
        </AppContext.Provider>
      </YMaps>
    </IonApp>
  );
};

export default App;
