import { IonButton, IonCol, IonContent, IonFooter, IonLabel, IonRow, IonSelectOption, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import useApi from "../../hooks/useApi";
import useToast from "../../hooks/useToast";
import { createEntity, updateEntity, searchEntity } from "../../services";
import Input from "../Input";
import Modal from "../Modal";
import Select from "../Select";
import ReactQuill from 'react-quill'
import ImageUploader from "../ImageUploader";
import userRoles from "../../data/userRoles";

const initialValue = {
    displayName: '',
    phoneNumber: '',
    role: 'customer'
}

export default function UserDetail({ isOpen, setOpen, initialUserId, ...rest }) {
    const [formData, setFormData] = useState(initialValue);
    const { categories } = useContext(AppContext)
    const [api, isLoading] = useApi()
    const [toast] = useToast()

    const onSubmit = async () => {
        try {
            if (initialUserId) {
                await api(updateEntity, { ...formData, entity: 'user' });
            } else {
                await api(createEntity, { ...formData, entity: 'user' })
            }
            setOpen(false)
        } catch (error) {

        }
    }

    const onIonChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const fetchUser = async () => {
        try {
            const data = await api(searchEntity, { entity: 'user', filter: { _id: initialUserId } })
            if (data.count !== 1) return toast({ message: 'Istifadeci tapilmadi.' })
            setFormData(data.data[0])
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        if (initialUserId) fetchUser()
        else setFormData(initialValue)
    }, [initialUserId])

    return <Modal title="İstifadəçi detalları"
        isOpen={isOpen}
        {...rest}
        setOpen={setOpen}>

        <IonContent className="ion-padding">
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.displayName,
                            name: 'displayName'
                        }}
                        label="Ad"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Input
                        inputProps={{
                            onIonChange,
                            value: formData.phoneNumber,
                            name: 'phoneNumber'
                        }}
                        label="Nömrə"
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <Select
                        selectProps={{
                            value: formData.role,
                            name: 'role',
                            onIonChange: e => setFormData({...formData, role: e.detail.value})
                        }}
                        label="Yeni rolu seçin"
                    >
                        {userRoles.map(s => (<IonSelectOption key={s.name} value={s.name}>
                            {s.translation}
                        </IonSelectOption>))}
                    </Select>
                </IonCol>
            </IonRow>
        </IonContent>
        <IonFooter>
            <IonToolbar>
                <IonButton disabled={isLoading} onClick={onSubmit} expand="block">
                    Təsdiqlə
                </IonButton>
            </IonToolbar>
        </IonFooter>
    </Modal>
}